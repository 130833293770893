import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { isExpired, decodeToken } from "react-jwt";
import axios from "axios";

const Video = () => {

  const [data, setData] = useState([
    { title: "Part 1", video_file: "https://www.youtube.com/embed/4oNvLjtwDf8?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 2", video_file: "https://www.youtube.com/embed/frOJywub6rI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 3", video_file: "https://www.youtube.com/embed/unpTe1su1SI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 4", video_file: "https://www.youtube.com/embed/HMaH2TK_ikM?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 5", video_file: "https://www.youtube.com/embed/McxQhadVTUI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },

  ])

  const fetchvideos = async () => {
    let result = await axios.get("https://api.gatezerodigital.com/api/get_video")
    // console.log(result.data)
    setData((prev) => [...prev, ...result.data])
  }


  useEffect(() => {
    // fetchvideos()
  }, []);


  return (
    <div className="video-page">
      <Container>
        <div className="row">
          <div className="heading-inner-pages ">
            <h1 className="about-heading">
              GateZero Video <span> Library </span>
            </h1>
          </div>
        </div>

        <div className="row">
          <div className="video-content">
            <p>
              Explore how GateZero provides transformative tools and enable your
              business to launch digital offense. Deploy your capital and
              efforts in the right direction and win your game.
            </p>
          </div>
        </div>
        <div className="video-cont mb-5 pb-2">
          <div className="row gx-5">
            <div class="col-sm-6 mb-4" >
              <h3>GateZero</h3>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/jQ3dve1v1js?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
            {data.map((item, index) => {
              return (
                <div class="col-sm-6 mb-4" key={index}>
                  <h3>{item.title}</h3>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe
                      class="embed-responsive-item"
                      src={item.video_file}
                      width="100%"
                      height="300"
                    ></iframe>
                  </div>
                </div>
              );
            })}

            <div class="col-md-6 video_bott_btn">
              <button type="button" class="btn-primary-2 mr-l">
                <a href="/signup">Signup for Free Training </a>{" "}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Video;
