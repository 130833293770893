import React, { useState, useEffect } from "react";
import { Form, Button, Container } from "react-bootstrap";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { setCruxRectangleBoard, setCRBStatus } from "../../Reducer/reducer";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";

function CruxRectangleBoard() {
  let loader;

  const [root, setRoot] = useState({});
  const [soldiff, setSolDiff] = useState({});
  const [currentalt, setCurrentAlt] = useState({});
  const [proddiff, setProdDiff] = useState({});
  const [prodchng, setProdChang] = useState({});
  const [bussgame, setBussGame] = useState({});
  const [marketneeds, setMarketNeeds] = useState("");
  const [status, setStatus] = useState("In Progess ...");
  const [issubmit, setSubmit] = useState(false);
  let [interval, setInt] = useState(null)
  let evaluation_id = localStorage.getItem("evaluation_id");

  const scoredata = useSelector((state) => state.scorereducer.scorevalue);

  const updateMarketNeeds = (val) => {
    setMarketNeeds(val);
    updateData('marketneeds', val)
  }

  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(interval)
  }, [interval])

  const updateData = (column, val) => {
    if (interval !== null) {
      clearTimeout(interval)
      setInt(null)
      setInt(setTimeout(() => {
        var data = {
          market_needs: column == 'marketneeds' ? val : marketneeds,
          root_causes: column == 'root' ? val : root,
          solution_differetiators: column == 'soldiff' ? val : soldiff,
          current_alternatives: column == 'currentalt' ? val : currentalt,
          product_differentiators: column == 'proddiff' ? val : proddiff,
          product_game_changers: column == 'prodchng' ? val : prodchng,
          business_goals: column == 'bussgame' ? val : bussgame,
        };
        setRedux(data, JSON.stringify({ cruxrectangleboard: data }), evaluation_id);
      }, 1000)
      )
    } else {
      setInt(setTimeout(() => {
        var data = {
          market_needs: marketneeds,
          root_causes: root,
          solution_differetiators: soldiff,
          current_alternatives: currentalt,
          product_differentiators: proddiff,
          product_game_changers: prodchng,
          business_goals: bussgame,
        };
        setRedux(data, JSON.stringify({ cruxrectangleboard: data }), evaluation_id);
      }, 1000)
      )
    }
  }

  const setRootValues = (num, value) => {
    let _root = { ...root, [num]: value.trim() };
    // if (_root[num] === "") delete _root[num];
    console.log(_root)
    setRoot(_root);
    updateData("root", _root)

  };
  const setSolDiffValues = (num, value) => {
    let _soldiff = { ...soldiff, [num]: value.trim() }
    _soldiff[num] = value.trim();
    if (_soldiff[num] === "") delete _soldiff[num];
    setSolDiff(_soldiff);
    updateData("soldiff", _soldiff)


  };
  const setCurrentAltValues = (num, value) => {
    let _currentalt = { ...currentalt, [num]: value.trim() }
    _currentalt[num] = value.trim();
    if (_currentalt[num] === "") delete _currentalt[num];
    setCurrentAlt(_currentalt);
    updateData("currentalt", _currentalt)

  };
  const setProdDiffValues = (num, value) => {
    let _proddiff = { ...proddiff, [num]: value.trim() }
    _proddiff[num] = value.trim();
    if (_proddiff[num] === "") delete _proddiff[num];
    setProdDiff(_proddiff);
    updateData("proddiff", _proddiff)

  };
  const setProdChangValues = (num, value) => {
    let _prodchng = { ...prodchng, [num]: value.trim() }
    _prodchng[num] = value.trim();
    if (_prodchng[num] === "") delete _prodchng[num];
    setProdChang(_prodchng);
    updateData("prodchng", _prodchng);

  };
  const setBussGameValues = (num, value) => {
    let _bussgame = { ...bussgame, [num]: value.trim() }

    _bussgame[num] = value.trim();
    if (_bussgame[num] === "") delete _bussgame[num];
    setBussGame(_bussgame);
    updateData("bussgame", _bussgame);

  };

  const setReduxStatus = (data, json, evaluation_id) => {
    dispatch(
      setCRBStatus({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setCruxRectangleBoard({
        data,
        json,
        evaluation_id,
      })
    );
    console.log('hitting api')
    setJson(json, evaluation_id);
  };

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }
  const submitData = (event, act) => {
    if (!scoredata) {
      event.preventDefault();
      event.stopPropagation();

      var data = {
        market_needs: marketneeds,
        root_causes: root,
        solution_differetiators: soldiff,
        current_alternatives: currentalt,
        product_differentiators: proddiff,
        product_game_changers: prodchng,
        business_goals: bussgame,
      };

      setRedux(data, JSON.stringify({ cruxrectangleboard: data }), evaluation_id);

      if (act == "n") navigate("/user/cruxcompetitive");
      else navigate(-1);
    } else {
      navigate("/user/cruxcompetitive");
    }
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      // console.log(data.cruxrectangleboard);
      if (data.cruxrectangleboard_status)
        setStatus(data.cruxrectangleboard_status);

      if (data.cruxrectangleboard) {
        var _data = data.cruxrectangleboard;
        setRoot(_data?.root_causes || {});
        setSolDiff(_data?.solution_differetiators || {});
        setCurrentAlt(_data?.current_alternatives || {});
        setProdDiff(_data?.product_differentiators || {});
        setProdChang(_data?.product_game_changers || {});
        setBussGame(_data?.business_goals || {});
        setMarketNeeds(_data?.market_needs || "");
      }
    }).finally(() => {
      setSubmit(false);
    })
  }, []);
  
  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  const updateStatus = (val) => {
    setStatus(val);
    setReduxStatus(
      val,
      JSON.stringify({ cruxrectangleboard_status: val }),
      evaluation_id
    );
  };
  return (
    <>
      <div>
        {/* <div style={{position: "absolute" , top:"0" , left: "0"}}> */}
        <Header2 />

        <div className="page-wraper">
          <div className="Probdesc-page">
            <Initiativeheader eid={evaluation_id} />
            <Container>
              {/* </div> */}
              {/* <div style={{margin: "100px 40px 100px 40px"}}> */}
              <div className="m-3">
                <div className="dimentional-title">
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 style={{ marginRight: '40px' }}>
                      CruxRectangleBoard
                    </h2>
                    <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                  </div>
                  {loader}
                  <div className="d-flex align-items-center ">
                    <p>
                      Status :<span className="text-success"> {status}</span>
                    </p>
                    {/* {status != "Approved" && (
                      <button
                        className="btn btn-success mx-3"
                        onClick={() => updateStatus("Approved")}
                      >
                        Approve
                      </button>
                    )}
                    {status == "Approved" && (
                      <button
                        className="btn btn-success mx-3"
                        onClick={() => updateStatus("Unapproved")}
                      >
                        UnApprove
                      </button>
                    )} */}
                    <button
                      className="btn btn-success mx-3"
                      onClick={() => updateStatus(status == "Approved" ? "In Progress..." : "Approved")}
                    >
                      {status === "Approved" ? "In Progress" : "Approve"}
                    </button>
                  </div>
                </div>

                <div className="cruxRectangleBoard">
                  <Form
                    className=" cruxrectangleboard d-flex justify-content-center"
                    style={{ margin: "0px auto 0px auto" }}
                  >
                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label>Market Needs/Problems</Form.Label>
                      <div className="dimentional-Needsbox">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={marketneeds}
                          as={"textarea"}
                          type={"text"}
                          rows={62}
                          cols={30}
                          onChange={(e) => updateMarketNeeds(e.target.value)}
                        ></Form.Control>
                      </div>
                    </Form.Group>
                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label>Root Causes</Form.Label>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={root["1"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) => setRootValues("1", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={root["2"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) => setRootValues("2", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={root["3"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) => setRootValues("3", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={root["4"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) => setRootValues("4", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={root["5"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) => setRootValues("5", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          as={"textarea"}
                          defaultValue={root["6"] || ""}
                          rows={5}
                          onChange={(e) => setRootValues("6", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          as={"textarea"}
                          defaultValue={root["7"] || ""}
                          rows={5}
                          onChange={(e) => setRootValues("7", e.target.value)}
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          as={"textarea"}
                          defaultValue={root["8"] || ""}
                          rows={5}
                          onChange={(e) => setRootValues("8", e.target.value)}
                        ></Form.Control>
                      </div>
                    </Form.Group>
                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label> Solution Differetiators</Form.Label>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["1"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("1", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["2"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("2", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["3"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("3", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["4"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("4", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["5"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("5", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["6"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("6", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["7"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("7", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={soldiff["8"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setSolDiffValues("8", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                    </Form.Group>
                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label> Current Alternatives</Form.Label>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["1"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("1", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["2"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("2", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["3"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("3", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["4"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("4", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["5"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("5", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["6"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("6", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["7"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("7", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={currentalt["8"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setCurrentAltValues("8", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                    </Form.Group>

                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label> Product Differentiators</Form.Label>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["1"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("1", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["2"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("2", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["3"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("3", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["4"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("4", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["5"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("5", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["6"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("6", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["7"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("7", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={proddiff["8"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdDiffValues("8", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                    </Form.Group>
                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label> Product Game Changers</Form.Label>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["1"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("1", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["2"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("2", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["3"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("3", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["4"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("4", e.target.value)
                          }
                        ></Form.Control>
                      </div>

                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["5"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("5", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["6"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("6", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["7"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("7", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={prodchng["8"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setProdChangValues("8", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                    </Form.Group>
                    <Form.Group style={{ margin: "5px" }}>
                      <Form.Label>Business Goals</Form.Label>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["1"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("1", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["2"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("2", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["3"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("3", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["4"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("4", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["5"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("5", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["6"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("6", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["7"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("7", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                      <div className="dimentional-box">
                        <Form.Control
                          readOnly={scoredata}
                          maxLength={1200}
                          defaultValue={bussgame["8"] || ""}
                          as={"textarea"}
                          rows={5}
                          onChange={(e) =>
                            setBussGameValues("8", e.target.value)
                          }
                        ></Form.Control>
                      </div>
                    </Form.Group>
                  </Form>
                </div>

                {/* <div className='d-flex justify-content-end m-3 ' style={{ margin: "0px 40px" }}>
        <button className='mx-3 btn btn-secondary' onClick={()=>console.log(root , soldiff , currentalt , bussgame , prodchng , proddiff)}>Back</button>
        <button className='mx-3 btn btn-secondary'>Next</button>
    </div> */}
                <div className="buyer-motivation ">
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="what-video">
                        <h3>Watch the video</h3>
                        <img src="../img/what-video.png" alt="" />
                      </div> */}
                      <div className="next-back-button">
                        {/* {loader} */}
                        <Button
                          variant="primary button-green"
                          onClick={(e) => submitData(e, "b")}
                        >
                          Back
                        </Button>
                        <Button
                          variant="primary button-blue"
                          onClick={(e) => submitData(e, "n")}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[11, 12, 13, 14, 15]} />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default CruxRectangleBoard;
