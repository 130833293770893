import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { isExpired, decodeToken } from "react-jwt";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import "jquery/dist/jquery.min.js";
import $ from 'jquery'


const Video = () => {

  const useremail = localStorage.getItem("email")

  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [AddBtn, setAddBtn] = useState(false);

  const [show, setShow] = useState(false);

  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);


  const [data, setData] = useState([
    { title: "Part 1", video_file: "https://www.youtube.com/embed/4oNvLjtwDf8?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 2", video_file: "https://www.youtube.com/embed/frOJywub6rI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 3", video_file: "https://www.youtube.com/embed/unpTe1su1SI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 4", video_file: "https://www.youtube.com/embed/HMaH2TK_ikM?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 5", video_file: "https://www.youtube.com/embed/McxQhadVTUI?modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },

  ]);

  const [newData, setNewData] = useState([
    { title: "Featured video", video_file: "https://www.youtube.com/embed/8CFlX1ujqNk?si=ZvgUGTTHeDlUgeFT&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 1", video_file: "https://www.youtube.com/embed/L8WlITnJoww?si=tVUWF1-U-AZK6_5o&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 2", video_file: "https://www.youtube.com/embed/iIEr7VdMgVo?si=vd27DtzsftvRbc1F&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 3", video_file: "https://www.youtube.com/embed/G9sYD8g_MEY?si=MIx1u9e6e8e-BXgg&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 4", video_file: "https://www.youtube.com/embed/GA4LgzBfxAs?si=AAkGSVTZlOz2Cyov&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 4.1", video_file: "https://www.youtube.com/embed/tFxcUhPFLDU?si=0g38p6ByHWtaWQgH&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 5", video_file: "https://www.youtube.com/embed/Bgx5ZDcfiUg?si=UfBYX0h754CqyqzJ&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 6", video_file: "https://www.youtube.com/embed/Lgw-4q4566M?si=H4I7l2aykkZrMSjR&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 7", video_file: "https://www.youtube.com/embed/EoerZjM0qBg?si=mdeHrrCkK079DhVU&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 8", video_file: "https://www.youtube.com/embed/ZYG0pmLHk0U?si=TjQyKoLY_RXQ_d-g&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 9", video_file: "https://www.youtube.com/embed/sYs3Y6jmf2k?si=FM_iirYv2ynY82e3&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 10", video_file: "https://www.youtube.com/embed/_ZAvb-exAxQ?si=kHaa1xVTpFVF7lr_&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 11", video_file: "https://www.youtube.com/embed/6OA-7HXJCn0?si=SFKh7ucq8qtETXzb&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 12", video_file: "https://www.youtube.com/embed/Lwx27xhFEic?si=J3hu0uGImuLMCCTc&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 13", video_file: "https://www.youtube.com/embed/rbfQAF0dGG0?si=8kHZrQY--E-m1OOr&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 14", video_file: "https://www.youtube.com/embed/uPcj2lnAPOg?si=7H2ZihN3vlwVi5it&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 15", video_file: "https://www.youtube.com/embed/oUcN0S6gpn4?si=uV_vwKsiLGL3HV6l&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 16", video_file: "https://www.youtube.com/embed/Nwzao-tT3Lw?si=5vbPH6_DlbC_Bisy&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 17", video_file: "https://www.youtube.com/embed/FXFOdr4RDi0?si=aNokZxinMuHCxmpM&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 18", video_file: "https://www.youtube.com/embed/cgsQaJy9flI?si=w7XCdMaLW4ZSr9ZL&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 19", video_file: "https://www.youtube.com/embed/1jtiAhPxGHw?si=Wik1O6ixaISKHbWW&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 20", video_file: "https://www.youtube.com/embed/efo4w2Wae5Y?si=mJt-z-Xzvaqa2czJ&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 21", video_file: "https://www.youtube.com/embed/wASLJNeOmqw?si=pRzafveAII5B4_rZ&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 22", video_file: "https://www.youtube.com/embed/CQSojuYEqQY?si=no96_a5lslcIL2Tl&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 23", video_file: "https://www.youtube.com/embed/MpwlGlVQiLA?si=jT131RWTQ9Xu8cKY&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 24", video_file: "https://www.youtube.com/embed/K29Ib1wIkl8?si=eD70IMG4xAbMoJ7q&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 25", video_file: "https://www.youtube.com/embed/M-iYbMUU2II?si=FSC6jkSCinsKi-43&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 26", video_file: "https://www.youtube.com/embed/FyMI6rOKqE4?si=t9wAeYhiufaNAjfR&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 27", video_file: "https://www.youtube.com/embed/Uwz1CvRS4FM?si=qX_6P4YCcPhInhw4&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
    { title: "Part 28", video_file: "https://www.youtube.com/embed/kYWbqffrr_A?si=QYz7odgLh8h9Xcf2&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },

  ]);

  const add = async () => {
    console.log(title, videoUrl);
    if (title !== "" && videoUrl !== "") {
      let index = videoUrl.indexOf("=");
      let link = videoUrl.slice(index + 1);
      let newurl =
        "https://www.youtube.com/embed/" +
        link +
        "?modestbranding=1&autohide=1&showinfo=0&controls=0";

      let videodata = {
        u_email: useremail,
        title: title,
        description: null,
        video_file: null,
        video_type: "1", // 1 for videolink , 2 for videofile 
        youtube_link: newurl
      }
      let token = localStorage.getItem("token")
      let headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }

      let result = await axios.post("https://api.gatezerodigital.com/api/add_video", videodata, headers)

      setTitle("");
      setVideoUrl("");
    }
    modalClose()
    fetchvideos()

  };

  const removeVideo = async (id) => {
    let token = localStorage.getItem("token")
    let headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    let data = {
      u_email: useremail
    }

    let result = await axios.post("https://api.gatezerodigital.com/api/delete_video/" + id, data, headers)
    console.log(result)
    fetchvideos()

  }

  const popUp = () => {
    modalShow()
  };

  const fetchvideos = async () => {
    let result = await axios.get("https://api.gatezerodigital.com/api/get_video")
    // console.log(result.data)
    setData((prev) => [...prev, ...result.data])
  }

  useEffect(() => {
    fetchvideos()
    // console.log(result.data)
  }, []);

  return (
    <div className="video-page">
      <div className="App p-4" >

        <Modal show={show} onHide={modalClose}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black", marginLeft: "20px" }}>Add Video</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
              }}
            >
              <div style={{ marginBottom: "20px" }}>
                <lable style={{ marginRight: "10px", color: "black" }}>TITLE</lable>
                <input
                  type={"text"}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ fontSize: 16, padding: " 5px 10px", borderRadius: "5px", borderColor: "gray", width: "100%" }}
                />
              </div>
              <div>
                <lable style={{ marginRight: "10px", color: "black" }}>LINK</lable>
                <input
                  type={"text"}
                  onChange={(e) => setVideoUrl(e.target.value)}
                  style={{ fontSize: 16, padding: " 5px 10px", borderRadius: "5px", borderColor: "gray", width: "100%" }}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={modalClose} size="lg">
              Cancel
            </Button>
            <Button variant="dark" size="lg" onClick={add}>Add</Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container>
        <div className="row">
          <div className="heading-inner-pages ">
            <h1 className="about-heading">
              GateZero Video <span> Library </span>
            </h1>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
              <Button
              variant="dark"
              size="lg"
                onClick={() => {
                  popUp();
                }}
              >
                 + Add Video
              </Button>
          </div> */}
        </div>
        <div className="row">
          <div className="video-content">
            <p>
              Explore how GateZero provides transformative tools and enable your
              business to launch digital offense. Deploy your capital and
              efforts in the right direction and win your game.
            </p>
          </div>
        </div>
        <div className="video-cont mb-5 pb-2">
          <div className="row gx-5">
            {newData.map((item, index) => {
              return (
                <div class="col-sm-6 mb-4" key={index}>
                  <h3>{item.title}</h3>
                  <div className="embed-responsive embed-responsive-16by9"
                    style={{ position: "relative" }}>

                    {/* <div onClick={()=> removeVideo(item.id)} style={{position: "absolute" , top: "10px" , right: "10px"}}>X</div> */}
                    <iframe
                      class="embed-responsive-item"
                      src={item.video_file}
                      width="100%"
                      height="450"

                    ></iframe>
                  </div>
                </div>
              );
            })}
            <div class="col-sm-6 mb-4" >
              <h3>GateZero</h3>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe width="100%" height="450" src="https://www.youtube.com/embed/jQ3dve1v1js?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
            </div>
            {/* {data.map((item, index) => {
              return (
                <div class="col-sm-6 mb-4" key={index}>
                  <h3>{item.title}</h3>
                  <div className="embed-responsive embed-responsive-16by9"
                    style={{ position: "relative" }}>                   
                    <iframe
                      class="embed-responsive-item"
                      src={item.video_file}
                      width="100%"
                      height="300"
                    ></iframe>
                  </div>
                </div>
              );
            })} */}

            <div class="col-md-6 video_bott_btn">
              <button type="button" class="btn-primary-2 mr-l">
                <a href="/signup">Signup for Free Training </a>{" "}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Video;
