import { createSlice } from '@reduxjs/toolkit'

export const scorereducre = createSlice({
  name: 'scoreinitiative',
  initialState: { 
    scorevalue: false,
  },
  reducers: {
    setScoreValue: (state,action) => {
      state.scorevalue = action.payload;
    }
  },
})

export const {
    setScoreValue
} = scorereducre.actions

export default scorereducre.reducer