import { Container, Form, Row, Button, Table } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSolutionriskscore } from "../../Reducer/reducer";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import ReactLoading from "react-loading";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";

export default function Solutionriskscore() {
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");

  const [issubmit, setSubmit] = useState(false);
  const [total, setTotal] = useState("");

  const [ps, setProblemScoreCard] = useState(0);
  const [pv, setProblemValidation] = useState(0);
  const [psms, setProblemScoreCardMarketsize] = useState(0);
  const [ca, setCruxalignment] = useState(0);
  const [sc, setSolutionscore] = useState(0);
  const [psf, setProblemScoreCardFrequency] = useState(0);
  const [r, setRevenue] = useState(0);
  const [bm, setbuyermotivation] = useState(0);
  const [other, setpmcmother] = useState(0);

  const scoredata = useSelector((state)=>state.scorereducer.scorevalue)

  let [state, setState] = useState({
    question1: 0,
    question1checked: false,

    question2: 0,
    question2checked: false,

    question3: 0,
    question3checked: false,

    question4: 0,
    question4checked: false,

    question5: 0,
    question5checked: false,

    question6: 0,
    question6checked: false,

    question7: 0,
    question7checked: false,

    question8: 0,
    question8checked: false,

    total: 0,
  });

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setSolutionriskscore({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const totalData = (data) => {
    let sum = 0;
    for (let [key, val] of Object.entries(data)) {
      if (
        key != "total" &&
        key != "question1checked" &&
        key != "question2checked" &&
        key != "question3checked" &&
        key != "question4checked" &&
        key != "question5checked" &&
        key != "question6checked" &&
        key != "question7checked" &&
        key != "question8checked"
      ) {
        sum += parseInt(val ? val : 0);
      }
    }
    return sum;
  };

  const getpmcm = () => {

    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {

        console.log("data.................")
        if (data.problemscorecard.total >= 10) {
          setProblemScoreCard(2);
        } else {
          setProblemScoreCard(-5);
        }

        if (data.problemvalidationscore.alltotal > 0) {
          setProblemValidation(2);
        } else {
          setProblemValidation( -3);
        }

        if (
          data.problemscorecard.marketsize == 0 ||
          data.problemscorecard.marketsize == 5
        ) {
          setProblemScoreCardMarketsize(1);
        } else {
          setProblemScoreCardMarketsize(-2);
        }

        if (data.cruxalignment.total >= 25) {
          setCruxalignment(4);
        } else {
          setCruxalignment(-2);
        }

        if (data.solutionscorecard.total >= 10) {
          setSolutionscore(2);
        } else {
          setSolutionscore(-10);
        }

        if (data.problemscorecard.problemfrequency == 3) {
          setProblemScoreCardFrequency(2);
        } else {
          setProblemScoreCardFrequency(-3);
        }

        if (data.revenuescore.total <= 5) {
          setRevenue(-3);
        } else {
          setRevenue(1);
        }

        if (data.buyermotivation.score >= 2) {
          setbuyermotivation(1);
        } else {
          setbuyermotivation(-1);
        }

        setpmcmother(
          data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption
        );
      }
    });
  };

  const setData = (e) => {
    if (e.target.checked == true) {
      setState((prev) => ({
        ...prev,
        [e.target.name]: parseInt(e.target.value),
        [e.target.name + "checked"]: e.target.checked,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [e.target.name]: 0,
        [e.target.name + "checked"]: false,
      }));
    }
    getpmcm();
  };

  let solutionrisk = (score) => {
    if (score == 0) {
      return 0;
    } else if (score <= 0) {
      return -1;
    }
  };

  let pmcm = (score) => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      // console.log(data);
      if (data) {
        let solutionRisk = solutionrisk(score);

        let finaldata =
          parseInt(ps + pv + psms + ca + sc + psf + r + bm + other) +
          parseInt(solutionRisk);

          console.log(ps , pv , psms , ca , sc , psf , r , bm , other)

        setJson(
          JSON.stringify({
            pmcmalignment: {
              ...data.pmcmalignment,
              total: parseInt(finaldata),
            },
          }),
          evaluation_id
        );

        navigate("/user/fundingscore");
      }
    });
  };

  const backButton = (event) => {
    if(!scoredata){
    event.preventDefault();
    event.stopPropagation();

    let score = totalData(state);
    setRedux(
      state,
      JSON.stringify({
        solutionriskscore: { ...state, total: score },
      }),
      evaluation_id
    );
    navigate(-1);
  }else{
    navigate(-1);
  }
  };

  const submitData = (event) => {
    if(!scoredata){
    event.preventDefault();
    event.stopPropagation();

    let score = totalData(state);
    setRedux(
      state,
      JSON.stringify({
        solutionriskscore: { ...state, total: score },
      }),
      evaluation_id
    );
    pmcm(score);
  }else{
    navigate("/user/fundingscore");
  }
  };

  useEffect(() => {
    setTotal(totalData({ ...state }));
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data.solutionriskscore) {
        setTotal(totalData(data.solutionriskscore));
        setState({ ...data.solutionriskscore });
      }
      setSubmit(false);
    });
  }, []);
  
  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  return (
    <div>
      <Header2 />

      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>
            <Row>
              {/* <div className="col-md-12">
                   
                    <div className="next-back-button">
                      {loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px', }}>
                  Solution Risk Scorecard
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>
                {loader}
                <p>
                  The solution risk scorecard evaluates if the risks of using
                  the solution for the intended customer/user are acceptable.
                  This is the risk that happens when the solution works as
                  designed. We are not evaluating the risk of service outage
                  here.
                </p>
              </div>
              <div className="table-list Solutionriskscore-table">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Risk</th>
                      <th>Select all that apply</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>No perceived or real risk</h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question1"
                            aria-label="option 1"
                            type="checkbox"
                            class="form-check-input"
                            value="0"
                            checked={state.question1checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>
                            User's concern of receiving spam emails as a result
                            of purchasing the service
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question2"
                            aria-label="option 2"
                            type="checkbox"
                            class="form-check-input"
                            value="-1"
                            checked={state.question2checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>
                            Fear of Loss / theft of private data (Financial
                            account / SSN) as a result of purchasing the service
                          </h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question3"
                            aria-label="option 3"
                            type="checkbox"
                            class="form-check-input"
                            value="-5"
                            checked={state.question3checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>Other Legal Law Suite Risk</h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question4"
                            aria-label="option 4"
                            type="checkbox"
                            class="form-check-input"
                            value="-10"
                            checked={state.question4checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>Other Government Compliance Violation Risk</h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question5"
                            aria-label="option 5"
                            type="checkbox"
                            class="form-check-input"
                            value="-15"
                            checked={state.question5checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>Health Risks</h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question6"
                            aria-label="option 6"
                            type="checkbox"
                            class="form-check-input"
                            value="-10"
                            checked={state.question6checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>Loss of Life Risk</h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question7"
                            aria-label="option 7"
                            type="checkbox"
                            class="form-check-input"
                            value="-40"
                            checked={state.question7checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="Solutionriskscore-list">
                          <h2>Other perceived risks</h2>
                        </div>
                      </td>
                      <td>
                        <div className="Revenuescore-parity">
                          <input
                            disabled={scoredata ? true : false}
                            name="question8"
                            aria-label="option 8"
                            type="checkbox"
                            class="form-check-input"
                            value="-5"
                            checked={state.question8checked}
                            onChange={(e) => setData(e)}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>
                        <div class="scrop-alignment-serch solutionriskscore-serch">
                          <h3>Total</h3>
                          <div class="form-group">
                            <Form.Control type="text" readOnly value={total} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
              <div className="buyer-motivation ">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                    <div className="next-back-button">
                      {loader}
                      <Button
                        variant="primary button-green"
                        onClick={backButton}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{padding: 0, marginTop: 18}}>
              <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[21]} />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
