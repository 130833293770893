import { color } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function ManageSubscription() {
  const style = {
    paddingTop: "75px",
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: "20px",
    backgroundColor: "#002b56",
    paddingRight: "80px"
  };

  const link_styles = {
    margin: "0 20px 20px 20px",
    cursor: "pointer",
    color: 'white'
  };

  const links = {
    color: 'white'
  }

  const [subscribe, setSubscribe] = useState('inactive')
  const [currentsub, setCurrentSub] = useState('inactive')
  const [assign, setAssign] = useState('inactive')
  const [subscriptions, setSubscriptions] = useState('inactive')

  const setFocus = (path) => {
    if (path === "subscribe") {
      setSubscribe('active');
      setCurrentSub('inactive');
      setAssign('inactive');
      setSubscriptions('inactive');
    }
    if (path === "current_subscription") {
      setSubscribe('inactive');
      setCurrentSub('active');
      setAssign('inactive');
      setSubscriptions('inactive');

    }
    if (path === "add_user") {
      setSubscribe('inactive');
      setCurrentSub('inactive');
      setAssign('active');
      setSubscriptions('inactive');

    }
    if (path === "subscriptions") {
      setSubscribe('inactive');
      setCurrentSub('inactive');
      setAssign('inactive');
      setSubscriptions('active');

    }
  }

  let url = window.location.pathname

  useEffect(() => {
    let newurl = url.split("/")
    setFocus(newurl[newurl.length - 1])
  }, [url])

  return (
    <div style={style}>
      {/* <div className="manageSubscription" style={link_styles} id="Subscribe">
        <Link className={subscriptions} style={links} to='/user/managesubscription/subscribe'>Subscriptions</Link>
      </div> */}
      <div className="manageSubscription" style={link_styles} id="Subscribe">
        <Link className={subscribe} style={links} to='/user/managesubscription/subscribe'>Subscribe</Link>
      </div>
      <div className="manageSubscription" style={link_styles} id="Current_Subscription">
        <Link className={currentsub} style={links} to='/user/managesubscription/current_subscription'>Current Subscription</Link>
      </div>
      <div className="manageSubscription" style={link_styles} id="Add_User">
        <Link className={assign} style={links} to='/user/managesubscription/add_user'>Add User</Link>
      </div>
    </div>
  )
}
export default ManageSubscription;
