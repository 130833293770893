import React, { useState, useEffect } from "react";
import { setJson, getJson } from "../../service/auth";
import { useSelector , useDispatch} from "react-redux";
import { setScoreValue } from "../../Reducer/scorereducer";

const Initiativeheader = (props) => {  
    const [initiative, setInitiative] = useState("");
    const [scorecard, setScorecard] = useState("");
    const [version, setVersion] = useState("");
    const [perm, setPerm] = useState(false);
    const [email, setEmail] = useState("");
    // const [loginemail, setLoginEmail] = useState("");
  const dispatch = useDispatch();

  const sdata = useSelector((state)=>state.scorereducer.scorevalue)

    useEffect(() => {
        getJson(props.eid).then((res) => {
          let data = JSON.parse(res.data.data.json);
          setEmail(data?.User?.email || "");
          if(data.Initiative){
            setInitiative(data.Initiative.fullname);
            setScorecard(data.Initiative.scorecard);
            setVersion(res.data.data.version);
            // setEmail(res.data.data.email)
            // console.log(res)
            setPerm(sdata)
          }
        });
      }, []);

   const loginemail = localStorage.getItem("email");
   const write_permission = localStorage.getItem("write_permission");
      useEffect(()=>{
        // alert(write_permission)
          dispatch(setScoreValue(write_permission == '0' ? false : true));
      },[write_permission])

    return (
        <>
          <div className="new-title">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  {/* <h4><i className="fa-solid fa-arrow-left-long"></i> New Initiative</h4> */}
                </div>
                <div className="col-md-8">
                  <ul className="new-title-list">
                    <li>
                      <h2>
                        Initiative Name:<span>{initiative}</span>
                      </h2>
                    </li>
                    <li>
                      <h2>
                        Scorecard Name:<span> {scorecard}</span>
                      </h2>
                    </li>
                    <li>
                      <h2>
                        Version:<span> {version}</span>
                      </h2>
                    </li>
                    {loginemail !== email && sdata  &&
                    
                    <li>
                      <h2>
                       <span>You only have read only access to this scorecard</span>
                      </h2>
                    </li>}
                  </ul>
                </div>
              </div>
            </div>
          </div> 
        </>
    );
}
 
export default Initiativeheader;