import React, { useState, useEffect } from 'react'

export default function VideoContainer(props) {

    let {ids} = props;

    const [newData, setNewData] = useState([
        { title: "Featured video", video_file: "https://www.youtube.com/embed/8CFlX1ujqNk?si=ZvgUGTTHeDlUgeFT&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 1, title: "Part 1", video_file: "https://www.youtube.com/embed/L8WlITnJoww?si=tVUWF1-U-AZK6_5o&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 2, title: "Part 2", video_file: "https://www.youtube.com/embed/iIEr7VdMgVo?si=vd27DtzsftvRbc1F&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 3, title: "Part 3", video_file: "https://www.youtube.com/embed/G9sYD8g_MEY?si=MIx1u9e6e8e-BXgg&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 4, title: "Part 4", video_file: "https://www.youtube.com/embed/GA4LgzBfxAs?si=AAkGSVTZlOz2Cyov&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 4.1, title: "Part 4.1", video_file: "https://www.youtube.com/embed/tFxcUhPFLDU?si=0g38p6ByHWtaWQgH&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 5, title: "Part 5", video_file: "https://www.youtube.com/embed/Bgx5ZDcfiUg?si=UfBYX0h754CqyqzJ&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 6, title: "Part 6", video_file: "https://www.youtube.com/embed/Lgw-4q4566M?si=H4I7l2aykkZrMSjR&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 7, title: "Part 7", video_file: "https://www.youtube.com/embed/EoerZjM0qBg?si=mdeHrrCkK079DhVU&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 8, title: "Part 8", video_file: "https://www.youtube.com/embed/ZYG0pmLHk0U?si=TjQyKoLY_RXQ_d-g&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 9, title: "Part 9", video_file: "https://www.youtube.com/embed/sYs3Y6jmf2k?si=FM_iirYv2ynY82e3&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 10, title: "Part 10", video_file: "https://www.youtube.com/embed/_ZAvb-exAxQ?si=kHaa1xVTpFVF7lr_&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 11, title: "Part 11", video_file: "https://www.youtube.com/embed/6OA-7HXJCn0?si=SFKh7ucq8qtETXzb&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 12, title: "Part 12", video_file: "https://www.youtube.com/embed/Lwx27xhFEic?si=J3hu0uGImuLMCCTc&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 13, title: "Part 13", video_file: "https://www.youtube.com/embed/rbfQAF0dGG0?si=8kHZrQY--E-m1OOr&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 14, title: "Part 14", video_file: "https://www.youtube.com/embed/uPcj2lnAPOg?si=7H2ZihN3vlwVi5it&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 15, title: "Part 15", video_file: "https://www.youtube.com/embed/oUcN0S6gpn4?si=uV_vwKsiLGL3HV6l&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 16, title: "Part 16", video_file: "https://www.youtube.com/embed/Nwzao-tT3Lw?si=5vbPH6_DlbC_Bisy&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 17, title: "Part 17", video_file: "https://www.youtube.com/embed/FXFOdr4RDi0?si=aNokZxinMuHCxmpM&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 18, title: "Part 18", video_file: "https://www.youtube.com/embed/cgsQaJy9flI?si=w7XCdMaLW4ZSr9ZL&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 19, title: "Part 19", video_file: "https://www.youtube.com/embed/1jtiAhPxGHw?si=Wik1O6ixaISKHbWW&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 20, title: "Part 20", video_file: "https://www.youtube.com/embed/efo4w2Wae5Y?si=mJt-z-Xzvaqa2czJ&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 21, title: "Part 21", video_file: "https://www.youtube.com/embed/wASLJNeOmqw?si=pRzafveAII5B4_rZ&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 22, title: "Part 22", video_file: "https://www.youtube.com/embed/CQSojuYEqQY?si=no96_a5lslcIL2Tl&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 23, title: "Part 23", video_file: "https://www.youtube.com/embed/MpwlGlVQiLA?si=jT131RWTQ9Xu8cKY&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 24, title: "Part 24", video_file: "https://www.youtube.com/embed/K29Ib1wIkl8?si=eD70IMG4xAbMoJ7q&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 25, title: "Part 25", video_file: "https://www.youtube.com/embed/M-iYbMUU2II?si=FSC6jkSCinsKi-43&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 26, title: "Part 26", video_file: "https://www.youtube.com/embed/FyMI6rOKqE4?si=t9wAeYhiufaNAjfR&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 27, title: "Part 27", video_file: "https://www.youtube.com/embed/Uwz1CvRS4FM?si=qX_6P4YCcPhInhw4&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
        { index: 28, title: "Part 28", video_file: "https://www.youtube.com/embed/kYWbqffrr_A?si=QYz7odgLh8h9Xcf2&modestbranding=1&autohide=1&showinfo=0&controls=0&rel=0" },
      
      ]);

    return (
        <div className="video-cont ">

            <div className={`${ids.length == 1 ? "w-50 mx-auto": "row gx-5"} mt-3`}>
                {newData.map((item, index) => {
                    if (!ids.includes(item.index)) {
                        return
                    }
                    return (
                        <div class={`${  ids.length == 1 ? "": "col-sm-6 mb-4"}`} key={index}>
                            <h3 style={{color: "black"}} className='  mb-3'>{item.title}</h3>
                            <div className="embed-responsive embed-responsive-16by9"
                                style={{ position: "relative" }}>

                                {/* <div onClick={()=> removeVideo(item.id)} style={{position: "absolute" , top: "10px" , right: "10px"}}>X</div> */}
                                <iframe
                                    class="embed-responsive-item"
                                    src={item.video_file}
                                    width="100%"
                                    height="450"
                                ></iframe>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}