import { Container, Form, Row, Button, Table } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRevenuescore } from "../../Reducer/reducer";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import ReactLoading from "react-loading";
import { setJson, getJson } from "../../service/auth";
import { DashboardCustomizeSharp } from "@mui/icons-material";
import { getValue } from "@mui/system";
import VideoContainer from "../../Frontend/Component/VideoContainer";

export default function Revenuescore() {
  const quadrant1 = [
    { id: 1, value: "1", text: "No clear alternative exists" },
    { id: 2, value: "0", text: "Alternatives with pain points" },
    { id: 3, value: "-1", text: "Mature Alternatives Exists " },
  ];

  const quadrant2 = [
    { id: 1, value: "6", text: "No clear alternative exists" },
    { id: 2, value: "3", text: "Alternatives with pain points" },
    { id: 3, value: "2", text: "Mature Alternatives Exists" },
  ];

  const quadrant3 = [
    { id: 1, value: "-1", text: "No clear alternative exists" },
    { id: 2, value: "-4", text: "Alternatives with pain points" },
    { id: 3, value: "-10", text: "Mature Alternatives Exists" },
  ];

  const quadrant4 = [
    { id: 1, value: "2", text: "No clear alternative exists" },
    { id: 2, value: "1", text: "Alternatives with pain points" },
    { id: 3, value: "0", text: "Mature Alternatives Exists" },
  ];
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");
  const totalValue = useRef();
  const [issubmit, setSubmit] = useState(false);

  const [total, setTotal] = useState(0);
  const [state, setState] = useState({
    total: 0,
  });

  const [quadrant, setQuadrant] = useState("");
  const [quadrantValues, setQuadrantValues] = useState([]);

  const [customer, setCustomer] = useState(false);
  const [actor, setActor] = useState(false);
  const [stakeholders, setStakeholders] = useState(false);

  const [ps, setProblemScoreCard] = useState(0);
  const [pv, setProblemValidation] = useState(0);
  const [psms, setProblemScoreCardMarketsize] = useState(0);
  const [ca, setCruxalignment] = useState(0);
  const [sc, setSolutionscore] = useState(0);
  const [psf, setProblemScoreCardFrequency] = useState(0);
  const [bm, setbuyermotivation] = useState(0);
  const [sr, setSolutionriskscore] = useState(0);
  const [other, setpmcmother] = useState(0);

  const [bonusval, setBonusVal] = useState("1");
  const [bonustext, setBonusText] = useState("No Quadrant Bonus");

  const scoredata = useSelector((state) => state.scorereducer.scorevalue)

  const setting_Form = () => {
    if (quadrant === "-3") {
      setQuadrantValues(quadrant1);
    } else if (quadrant === "3") {
      setQuadrantValues(quadrant2);
    } else if (quadrant === "-4") {
      setQuadrantValues(quadrant3);
    } else if (quadrant === "1") {
      setQuadrantValues(quadrant4);
    } else if (quadrant === "") {
      setQuadrantValues([]);
    }
  };

  const total_val = () => {
    let totalVal = 0;

    if (customer === true) totalVal = 1;

    if (actor === true) totalVal = -3;

    if (stakeholders === true) totalVal = -1;

    if (customer === true && actor === true) totalVal = 2;

    if (actor === true && stakeholders === true) totalVal = -1;

    if (customer === true && stakeholders === true) totalVal = 2;

    if (customer === true && actor === true && stakeholders === true)
      totalVal = 5;

    setState({ ...state, purchasedecision: totalVal });
  };

  useEffect(() => {
    total_val();
  }, [customer, actor, stakeholders]);

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setRevenuescore({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const getpmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {
        if (data.problemscorecard.total >= 10) {
          setProblemScoreCard(2);
        } else {
          setProblemScoreCard(-5);
        }

        if (data.problemvalidationscore.alltotal > 0) {
          setProblemValidation(2);
        } else {
          setProblemValidation(-3);
        }

        if (
          data.problemscorecard.marketsize == 0 ||
          data.problemscorecard.marketsize == 5
        ) {
          setProblemScoreCardMarketsize(1);
        } else {
          setProblemScoreCardMarketsize(-2);
        }

        if (data.cruxalignment.total >= 25) {
          setCruxalignment(4);
        } else {
          setCruxalignment(-2);
        }

        if (data.solutionscorecard.total >= 10) {
          setSolutionscore(2);
        } else {
          setSolutionscore(-10);
        }

        if (data.problemscorecard.problemfrequency == 3) {
          setProblemScoreCardFrequency(2);
        } else {
          setProblemScoreCardFrequency(-3);
        }

        if (data.buyermotivation.score >= 2) {
          setbuyermotivation(1);
        } else {
          setbuyermotivation(-1);
        }

        if (data.solutionriskscore.total == 0) {
          setSolutionriskscore(0);
        } else {
          setSolutionriskscore(-1);
        }

        // console.log(pmcmtotal)

        setpmcmother(
          data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption
        );
      }
    });
  };

  const setData = (e) => {
    if (e.target.name == 'position') {
      let values = state;
      values.position = e.target.value;
      values.subcategory = '';
      setState(values);
    } else {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    }
    getpmcm();
  };

  const getTotal = (state) => {
    let total = 0;
    if (
      state.position &&
      state.subcategory &&
      state.motivationTransition &&
      state.purchasedecision &&
      state.frequencyProblem &&
      state.compellingEvent &&
      state.eventFrequency &&
      state.customerProblemAware &&
      state.marketSize &&
      state.modelBarrier
    ) {
      total =
        parseInt(state.position) +
        parseInt(state.subcategory) +
        parseInt(state.motivationTransition) +
        parseInt(state.purchasedecision) +
        parseInt(state.frequencyProblem) +
        parseInt(state.compellingEvent) +
        parseInt(state.eventFrequency) +
        parseInt(state.customerProblemAware) +
        parseInt(state.marketSize) +
        parseInt(state.modelBarrier);
    }

    return total;
  };

  const totalvalues = (state) => {
    let val = 0;

    if (
      state.priceModel &&
      state.transactionValue &&
      state.transactionFrequency
    ) {
      let multi =
        parseInt(state.priceModel) *
        parseInt(state.transactionValue) *
        parseInt(bonusval);

      val = parseInt(multi) + parseInt(state.transactionFrequency);
    }
    return val;
  };

  let revenue = (score) => {
    if (score <= 5) {
      return -3;
    } else {
      return 1;
    }
  };

  let pmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      // console.log(data);
      if (data) {
        let revenuescore = revenue(total);

        let finaldata =
          parseInt(ps + pv + psms + ca + sc + psf + bm + sr + other) +
          parseInt(revenuescore);

        // console.log(buyerdata)
        setJson(
          JSON.stringify({
            pmcmalignment: {
              ...data.pmcmalignment,
              total: parseInt(finaldata),
            },
          }),
          evaluation_id
        );

        navigate("/user/solutionriskscore");
      }
    });
  };

  const backButton = (event) => {
    if (!scoredata) {
      event.preventDefault();
      event.stopPropagation();

      var fData = state;
      fData = {
        ...fData,
        ...{
          customer: customer ? 1 : 0,
          actor: actor ? 1 : 0,
          stakeholders: stakeholders ? 1 : 0,
        },
      };

      setRedux(
        fData,
        JSON.stringify({
          revenuescore: { ...fData, total: parseInt(total.toFixed(2)) },
        }),
        evaluation_id
      );
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const submitData = (event) => {
    if (!scoredata) {
      event.preventDefault();
      event.stopPropagation();

      var fData = state;
      fData = {
        ...fData,
        ...{
          customer: customer ? 1 : 0,
          actor: actor ? 1 : 0,
          stakeholders: stakeholders ? 1 : 0,
        },
      };

      console.log(fData);

      setRedux(
        fData,
        JSON.stringify({
          revenuescore: { ...fData, total: parseInt(total.toFixed(2)) },
        }),
        evaluation_id
      );

      pmcm();
    } else {
      navigate("/user/solutionriskscore");
    }
  };

  useEffect(() => {
    if (quadrant === "3" && state.marketSize === "5") {
      if (state.subcategory == "6") {
        setBonus(6);
      } else if (state.subcategory == "3") {
        setBonus(3);
      } else {
        setBonusText("No Quadrant Bonus");
        setBonusVal("1");
      }
    } else {
      setBonusText("No Quadrant Bonus");
      setBonusVal("1");
    }

    setTotal(getTotal(state) * totalvalues(state));
  }, [state]);

  useEffect(() => {
    setTotal(getTotal(state) * totalvalues(state));
  }, [bonustext, bonusval])

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true)
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);

      console.log(data);

      if (data.revenuescore) {
        setState({ ...data.revenuescore });

        // alert(data.revenuescore.customer)
        if (data.revenuescore?.customer) setCustomer(true);
        if (data.revenuescore?.actor) setActor(true);
        if (data.revenuescore?.stakeholders) setStakeholders(true);

        setQuadrant(data.revenuescore.position);
      }
    }).finally(() => {
      setSubmit(false)
    })
  }, []);

  useEffect(() => {
    setting_Form();
  }, [quadrant]);

  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  const setBonus = (data) => {
    if (data === 6) {
      setBonusText("Second Quadrant - 6");
      setBonusVal("5");
    } else {
      setBonusText("Second Quadrant - 3");
      setBonusVal("3");
    }
  };

  return (
    <div>
      <Header2 />

      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>
            <Row>
              {/* <div className="col-md-12">
                    
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px', }}>
                    Revenue Score
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>
                {loader}
                <p>
                  The revenue score measures the strength of your initiatives to
                  monetize.
                </p>
              </div>
              <div className="table-list Revenuescore-table">
                <div className="revenue-form">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="form-group">
                        <Form.Label>
                          Buyer Motivation Quadrant Position
                        </Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.position}
                          name={"position"}
                          onChange={(e) => {
                            setQuadrant(e.target.value);
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-3">1st Quadrant</option>
                          <option value="3">2nd Quadrant</option>
                          <option value="-4">3rd Quadrant</option>
                          <option value="1">4th Quadrant</option>
                        </Form.Select>

                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Customer Problem Aware</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.customerProblemAware}
                          name={"customerProblemAware"}
                          onChange={(e) => {
                            // setProblemAware(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="3">Yes</option>
                          <option value="1">Somewhat</option>
                          <option value="-3">No</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formBasicName"
                      >
                        <Form.Label>
                          Buyer Motivation Quadrant Subcategory
                        </Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          required
                          value={state.subcategory}
                          name={"subcategory"}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          {quadrantValues.map((item) => (
                            <option key={item.id} value={item.value}>
                              {item.text}
                            </option>
                          ))}
                          ;
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Market Size</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.marketSize}
                          name={"marketSize"}
                          onChange={(e) => {
                            // setMarketSize(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-4">Few Customers</option>
                          <option value="0">000's of Customers</option>
                          <option value="5">Millions of Customers</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Motivation Parity Transition</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.motivationTransition}
                          name={"motivationTransition"}
                          onChange={(e) => {
                            // setMotivationTransition(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">Positive</option>
                          <option value="0">No Transition</option>
                          <option value="-2">Negative</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Mental Model Barrier</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.modelBarrier}
                          name={"modelBarrier"}
                          onChange={(e) => {
                            // setModleBarrier(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Yes</option>
                          <option value="0">No</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group "
                        controlId="formGridState"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <Form.Label> Purchase Decision Alignment</Form.Label>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                            className="checkbox"
                          >
                            <Form.Check
                              disabled={scoredata ? true : false}
                              name="customer"
                              checked={customer}
                              onChange={(e) => setCustomer(!customer)}
                              style={{ color: "red" }}
                            />
                            <div
                              style={{
                                // width: "120px",
                                margin: "0px 20px 0px 10px",
                                color: "black",
                              }}
                            >
                              Customer
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                            className="checkbox"
                          >
                            <Form.Check
                              disabled={scoredata ? true : false}
                              name="actor"
                              checked={actor}
                              onChange={(e) => setActor(!actor)}
                            />
                            <div
                              style={{
                                // width: "120px",
                                margin: "0px 20px 0px 10px",
                                color: "black",
                              }}
                            >
                              Actor
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                            className="checkbox"
                          >
                            <Form.Check
                              disabled={scoredata ? true : false}
                              name="stakeholder"
                              checked={stakeholders}
                              onChange={(e) => setStakeholders(!stakeholders)}
                            />
                            <div
                              style={{
                                // width: "120px",
                                margin: "0px 20px 0px 10px",
                                color: "black",
                              }}
                            >
                              Stakeholder
                            </div>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Pricing model</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.priceModel}
                          name={"priceModel"}
                          onChange={(e) => {
                            // setPricingModel(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">One Time</option>
                          <option value="2">Subscription</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Frequency of Problem</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.frequencyProblem}
                          name={"frequencyProblem"}
                          onChange={(e) => {
                            // setFrequencyProblem(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">Low</option>
                          <option value="3">Medium</option>
                          <option value="10">High</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Average Transaction value</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.transactionValue}
                          name={"transactionValue"}
                          onChange={(e) => {
                            // settransactionValue(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">Under $100</option>
                          <option value="15"> 100's of dollor</option>
                          <option value="3">1000's of dollor</option>
                          <option value="5">10,000's of dollor</option>
                          <option value="10">100,000's of dollor</option>
                          <option value="20">$ 1 Million</option>
                          <option value="50">
                            Multiple Millions of dollors
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Compelling Event</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.compellingEvent}
                          name={"compellingEvent"}
                          onChange={(e) => {
                            // setEvent(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="2">Yes</option>
                          <option value="-2">No</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Transaction Frequency</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.transactionFrequency}
                          name={"transactionFrequency"}
                          onChange={(e) => {
                            // setEvent(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="20">Per Second</option>
                          <option value="10">Per Minute</option>
                          <option value="5">Per Hour</option>
                          <option value="2">Per Day</option>
                          <option value="0">Per Month</option>
                          <option value="0.9">Other</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Compelling Event Frequency</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.eventFrequency}
                          name={"eventFrequency"}
                          onChange={(e) => {
                            // setEventFrequency(parseInt(e.target.value));
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="4">Daily</option>
                          <option value="3">Weekly</option>
                          <option value="2">Monthly</option>
                          <option value="1">Multiple times a year</option>
                          <option value="-1">Yearly</option>
                          <option value="-3">Other</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Label>Quadrant Bonus</Form.Label>
                        <Form.Select
                          disabled={scoredata ? true : false}
                          value={state.quadrantBonus}
                          name={"quadrantBonus"}
                        >
                          <option value={bonusval}>{bonustext}</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="problem-total">
                        <span>Total</span>
                        <Form.Control
                          className="form-input-total"
                          type="text"
                          readOnly
                          defaultValue={state.total}
                          value={parseInt(total)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="buyer-motivation ">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                    <div className="next-back-button">
                      {loader}
                      <Button
                        variant="primary button-green"
                        onClick={backButton}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[20]} />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
