import { Container, Form, Row, Button, Table } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import { useSelector, useDispatch } from "react-redux";
import { setCruxalignment } from "../../Reducer/reducer";
import ReactLoading from "react-loading";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";

export default function Cruxalignment() {
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");

  const [issubmit, setSubmit] = useState(false);
  const [total, setTotal] = useState("");
  const [is_nogo, setNogo] = useState(false);

  const [ps, setProblemScoreCard] = useState(0)
  const [pv, setProblemValidation] = useState(0)
  const [psms, setProblemScoreCardMarketsize] = useState(0)
  const [bm, setbuyermotivation] = useState(0)
  const [sc, setSolutionscore] = useState(0)
  const [psf, setProblemScoreCardFrequency] = useState(0)
  const [r, setRevenue] = useState(0)
  const [sr, setSolutionriskscore] = useState(0)
  const [other, setpmcmother] = useState(0)

  const scoredata = useSelector((state) => state.scorereducer.scorevalue)

  const [state, setState] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    total: 0
  });

  const getpmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {

        if (data.problemscorecard.total >= 10) {
          setProblemScoreCard(2)
        } else {
          setProblemScoreCard(- 5)


        }

        if (data.problemvalidationscore.alltotal > 0) {
          setProblemValidation(2)

        } else {
          setProblemValidation(-3)

        }

        if (
          data.problemscorecard.marketsize == 0 ||
          data.problemscorecard.marketsize == 5
        ) {
          setProblemScoreCardMarketsize(1)
        } else {
          setProblemScoreCardMarketsize(-2)
        }

        if (data.buyermotivation.score >= 2) {
          setbuyermotivation(1);
        } else {
          setbuyermotivation(-1)
        }

        if (data.solutionscorecard.total >= 10) {
          setSolutionscore(2)
        } else {
          setSolutionscore(-10)
        }

        if (data.problemscorecard.problemfrequency == 3) {
          setProblemScoreCardFrequency(2)

        } else {
          setProblemScoreCardFrequency(-3)


        }

        if (data.revenuescore.total <= 5) {
          setRevenue(- 3)

        } else {
          setRevenue(1)
        }

        if (data.solutionriskscore.total == 0) {
          setSolutionriskscore(0)


        } else {
          setSolutionriskscore(- 1)

        }

        // console.log(pmcmtotal)

        setpmcmother(data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption)

      }

    });
  };

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setCruxalignment({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const setData = (e) => {
    const { name, value } = e.target;

    setState((prev) => ({
      ...prev,
      [name]: parseInt(value),
    }));
    getpmcm()
  };

  const totalData = (data) => {
    let sum = 0;
    setNogo(false)

    for (let [key, val] of Object.entries(data)) {
      if (key != "total") {

        if (key == "question1" && val == "0") {
          setNogo(true)
        } else if (key == "question2" && val == "0") {
          setNogo(true)
        } else if (key == "question4" && val == "0") {
          setNogo(true)
        } else if (key == "question6" && val == "0") {
          setNogo(true)
        }

        sum += (val ? val : 0);


      }
    }
    return parseInt(sum);
  };

  let cruxalign = (score) => {
    if (score >= 25) {
      return 4;
    } else {
      return -2;
    }
  };

  let pmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      console.log(data);
      if (data) {

        let cruxalignscore = cruxalign(parseInt(total));

        let finaldata = parseInt(ps + pv + psms + bm + sc + psf + r + sr + other) + parseInt(cruxalignscore)

        // console.log(buyerdata)
        setJson(JSON.stringify({ pmcmalignment: { ...data.pmcmalignment, total: parseInt(finaldata) } }), evaluation_id)

        navigate("/user/cruximpact");

      }
    });
  };

  const backButton = (event) => {

    if (!scoredata) {
      setRedux(
        state,
        JSON.stringify({ cruxalignment: { ...state, total: parseInt(total) }, is_nogo_crux: is_nogo }),
        evaluation_id
      );

      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const submitData = (event) => {

    if (!scoredata) {
      setRedux(
        state,
        JSON.stringify({ cruxalignment: { ...state, total: parseInt(total) }, is_nogo_crux: is_nogo }),
        evaluation_id
      );

      pmcm();
    } else {
      navigate("/user/cruximpact");
    }
  };

  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  useEffect(() => {
    setTotal(totalData({ ...state }));
  }, [state]);


  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data.cruxalignment) {
        setTotal(totalData(data.cruxalignment));
        setState({ ...data.cruxalignment });
      }
    }).finally(() => {
      setSubmit(false);
    })
  }, []);

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  return (
    <div>
      <Header2 />
      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>
            <Row>
              {/* <div className="col-md-12">
                 
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => {
                          submitData(e);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px' }}>
                    CRUX - Alignment Scorecard
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>
                {loader}
                <p>
                  CRUX - Alignment scorecard evaluates the alignment strength
                  amongst problem, root causes, solution, and business goals.
                </p>
              </div>
              <div className="table-list Cruxalignment-list">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Ratings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h2>
                          Are you able to identify all the root causes for the
                          problem or need?
                        </h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question1"
                            value={state.question1}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="5">All root causes identified</option>
                            <option value="2">Some of the root causes are identified</option>
                            <option value="0">No root cause could be identified</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          Are you able to identify solution differentiators that
                          have significant impact on root cause elimination?
                        </h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question2"
                            value={state.question2}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="5">Yes</option>
                            <option value="1">Somewhat</option>
                            <option value="0">No</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          Are there any frequent root causes that are not
                          solvable?
                        </h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question3"
                            value={state.question3}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="-10">Yes</option>
                            <option value="5">No</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          Can you digitally implement the solution
                          differentiators?
                        </h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question4"
                            value={state.question4}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="5">All of it</option>
                            <option value="2">Some of it</option>
                            <option value="0">None of it</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>What is Crux Competitive Score?</h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question5"
                            value={state.question5}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="5">More than 30% of winner and no losing differentiator.</option>
                            <option value="-5">Something different</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          Are you able to clearly identify how a successful
                          product will help your business?
                        </h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question6"
                            value={state.question6}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="5">Yes</option>
                            <option value="1">Somewhat</option>
                            <option value="0">No</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h2>
                          Is there consensus amongst stakeholders on product
                          business goal alignment?
                        </h2>
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="question7"
                            value={state.question7}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">-- Select One --</option>
                            <option value="5">Yes</option>
                            <option value="-1">Somewhat</option>
                            <option value="-5">No</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <h2>
                          <b>Total</b>
                        </h2>
                      </td>

                      <td>
                        <Form.Control className="form-input-total" type="text" readOnly value={total} />
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
              <div className="buyer-motivation ">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={backButton}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => {
                          submitData(e);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[17]} />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
