import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getFreetrail, getPackages, products, Purchasedproducts } from "../service/auth";
import ManageSubscription from "./ManageSubscription";

export default function Product() {

  const [validated, setValidated] = useState(true);
  const [checkoutLink, setcheckoutLink] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [products_, setproducts] = useState([])

  const [purchasedPlans, setPurchasedPlans] = useState([])

  const email = localStorage.getItem("email");

  const componentDidMount = async () => {
    if (!userEmail) {
      setUserEmail(email);
    }

    let result = await getFreetrail(email);
    if (result.data.response == "error") {
      setValidated(false);
    }
  };
  componentDidMount();

  const packages_data = async () => {

    let res = await getPackages(email);
    if (res.data) {
      setPurchasedPlans(res.data.package_data)
    }
    let packages = await products();
    console.log(packages.data)
    if (packages.data.success) {
      setproducts(packages.data.data)
    } else {

    }
  }

  useEffect(() => {
    if (userEmail) {
      setcheckoutLink("/user/Checkout");
    } else {
      setcheckoutLink("/Checkout");
    }
    packages_data();

  }, []);

  return (
    <>
      <ManageSubscription />
      <div className="product-admin-page">
        <div className="container">
          {(products_?.length > 0) ? products_.map((e) => {
            if (e.ProductPackages.length == 0) {
              return
            }if(e.ProductName == "VideoTraining" || e.ProductName == "PersonalTraining"  ){
              return
            }
            return (
              <>
                <h1>{e.ProductName}</h1>
                <div className="row product-section">
                  {e.ProductPackages.map((ele) => {
                    let purchased = false;
                    purchasedPlans.map((plan) => {
                      if (plan.ProductID == ele.ProductId) {
                        if (plan.PackageID == ele.PackageID) {
                          purchased = true
                        }
                      }
                    })
                    return (
                      <div className="col-md-3" style={{ marginBottom: "10px" }}>
                        <Card>
                          <Card.Body>
                            <Card.Text>
                              <ul>
                                <li className="un-hd">{ele.PackageName}</li>
                                {ele.PackageType == 'gatezero' && <li>{ele.NoOfUsers == '-1' ? "Unlimited" : ele.NoOfUsers} Users - {ele.NoOfScorecard == '-1' ? "Unlimited" : ele.NoOfScorecard} scorecards</li>}
                                {ele.PackageType == "estimation" && <li>{`${ele.NoOfUsers == '-1' ? "Unlimited" : ele.NoOfUsers}`} {ele.NoOfUsers > 1 ? "Users" : "User"}</li>}
                                <li>{ele.PackageDescription}</li>
                              </ul>
                              <div className="price-month">
                                <span>{`${ele.CostInUSD == '0' ? 'Free' : "$" + ele.CostInUSD}`}</span>{ele.CostInUSD == '0' ? "/" + ele?.FreeTrialPeriodInDays + ' days' || '0' : "/month"} {`${ele.IsOneTime == "0" ? "" : "- One Time Payment"}`}
                              </div>
                            </Card.Text>
                            {(purchased || ele.IsFreeTrial == "1") ?
                              <Link
                                to="#"
                                className="btn btn-default disabled"
                              >
                                {ele.IsFreeTrial == "1" ? "Already Utilized" : "Activated"}
                              </Link> :
                              <Link
                                to="/user/Checkout"
                                state={{ from: ele.CostInUSD, type: e.ProductName.toLowerCase(), productData: e, packageData: ele }}
                                className="btn btn-default"
                                id={`${ele.CostInUSD}`}
                              >
                                Activate
                              </Link>}
                          </Card.Body>
                        </Card>
                      </div>
                    )
                  })}
                </div >
              </>
            )
          }) : <div style={{ color: 'black', textAlign: 'center', fontSize: '20px' }}>No Package Available!</div>}
        </div>
      </div>
    </>
  )
}
