import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Button } from "react-bootstrap";
// import Alert from "react-bootstrap/Alert";
// import ReactLoading from "react-loading";
import CountrySelect from "react-bootstrap-country-select";
import { checkoutForm, setFreetrail } from "../service/auth";
import PaypalExpressBtn from './Paypal';
import { isExpired, decodeToken } from "react-jwt";
import Alert from "react-bootstrap/Alert";
import { useForkRef } from "@mui/material";


export default function Checkout() {
    const navigate = useNavigate();
    let loader;
    const [validated, setValidated] = useState(false);
    const [issubmit, setSubmit] = useState(true);
    const [type, setType] = useState('');
    const [from, setFrom] = useState('');
    const [productData, setproductData] = useState({});
    const [packageData, setPackageData] = useState({});


    const location = useLocation();

    // const { type = '', from = '' } = location?.state

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [packages, setPackage] = useState("");
    const [display_string, setDisplayString] = useState("")
    const [u_email, setU_email] = useState("")
    const [packType, setPackType] = useState('')
    const [token, setToken] = useState('')
    const [countryInvalid, setCountryinvalid] = useState(false)

    const [submiting, setSubmiting] = useState(false)

    const [showAlert, setAlert] = useState(false);
    const [showAlertMsg, setAlertMsg] = useState({ type: "", msg: "" });

    const estimationurl = process.env.REACT_APP_BASE_ESTIMATION_URL


    if (!token) {

        let Localtoken = localStorage.getItem("token");
        if (Localtoken) {
            setToken(Localtoken)
        }

    }
    if (!u_email) {
        let email = localStorage.getItem("email");
        if (email) {
            setU_email(email)
        }
    }


    const componentDidMount = async () => {

        if (type == "gatezero_free") {
            const email = localStorage.getItem("email");
            let result = await setFreetrail(email, type)
            navigate("/user");
        } else {
            const email = localStorage.getItem("email");
            let result = await setFreetrail(email, type)
            navigate("/user");
            window.open(estimationurl + token, "_blank")
        }
    }

    useEffect(() => {

        if (location?.state) {
            const { type, from, packageData, productData } = location.state;
            // alert(type)
            setType(type);
            setFrom(from);
            setproductData(productData);
            setPackageData(packageData);
            console.log(type)
            setPackType(type)

            // if (type == "gatezero") {
            //     setPackType("gatezero")
            // } else {
            //     setPackType("estimation")
            // }


            if (from == 'free') {
                componentDidMount();
            }

        } else {
            navigate('/user/managesubscription/subscribe')
        }

        const isMyTokenExpired = isExpired(token);
        if (isMyTokenExpired) {
            navigate("/signingupcheckout");
        }

        let userData = localStorage.getItem('User');
        if (userData) {

            let user = JSON.parse(userData);
            setFirstName(user.user?.name || '')
            setEmail(user.user?.email || '')
            setPhone(user.user?.phone || '')
            setAddress(user.user?.address || '')
            setZip(user.user?.zip || '')
            setCountry(user.user?.country || '')
            setCity(user.user?.city || '')
            setToken(user.access_token);
        }

    }, [])




    const submitData = async (event) => {
        event.preventDefault();
        setValidated(true);

        setSubmiting(true)
        try {
            // const u_email = localStorage.getItem("email");
            // let result = await checkoutForm(firstname, lastname, email, address, phone, city, country.name, from, zip, u_email , packType)
            // console.log(result);
            // setCheckoutId(result.data.checkoutId)
            if (country.name) setCountryinvalid(false)
            else { setCountryinvalid(true) }
            if (firstname && lastname && email && address && phone && city && country.name && from && zip && u_email && packType) {

                setSubmit(false);
            }

        } catch (err) {
            setSubmit(true);
        }
    };

    const checkCountry = () => {
        if (countryInvalid) {
            setSubmiting(true)
            setCountryinvalid(false);
        }
    }

    function AlertShow(props) {
        if (showAlert) {
            return (
                <Alert variant={props.type}>
                    <Alert.Heading>Alert !</Alert.Heading>
                    <p>{props.text}</p>
                </Alert>
            );
        }
    }
    if (issubmit) {
        return (
            <div>
                <div className="login-page admin-login">
                    <div className="container form-container">
                        <h2 className="checkout-h2">Checkout</h2>
                        <AlertShow type={showAlertMsg.type} text={showAlertMsg.msg} />
                        <div className="checkout-form">
                            <Form noValidate validated={validated} onSubmit={submitData} className="row" >
                                <h4 className="mt-2 mb-4">Billing Information</h4>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="firstname"
                                        label='First Name'
                                        placeholder='First Name'
                                        value={firstname}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="lastname"
                                        label='Last Name'
                                        placeholder='Last Name'
                                        value={lastname}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="email"
                                        type="email"
                                        label='Email'
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="phone"
                                        type="number"
                                        placeholder='Contact No.'
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="address"
                                        placeholder='Enter Your Address'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="zip"
                                        type="number"
                                        placeholder='Zip Code'
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <CountrySelect
                                        value={country}
                                        onChange={(e) => { setCountry(e); checkCountry(); }}
                                        className={`${submiting ? countryInvalid ? "invalid" : 'valid' : ''}`}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4 col-md-6" controlId="formBasicName">
                                    <Form.Control
                                        required
                                        name="city"
                                        placeholder='City'
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>

                                {/* <Form.Group>
                                    <Form.Select 
                                        required
                                        aria-label="Default select example"
                                        onChange={(e) => setPackage(e.target.value)}
                                    >
                                        <option value="">Buy Scorecard</option>
                                        <option value="one">1 User in $149 / Month</option>
                                        <option value="ten">1-10 Users $349 / Month</option>
                                        <option value="unlimited">Unlimited Users $649 / Month</option>
                                    </Form.Select>
                                </Form.Group> */}
                                <div className="sign-up-button">
                                    {loader}
                                    <Button type="submit" variant="primary">
                                        Pay ${from}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div>
                <div className="login-page admin-payment-mode">
                    <div className="container">
                        <div className="checkout-form border-none">
                            <div id="paypal-button-container" className="payment-wrapper" style={{ margin: 'auto' }}></div>
                            <PaypalExpressBtn data={'unlimited'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }} planData={packageData} productData={productData} ></PaypalExpressBtn>
                        </div>
                    </div>
                </div>
            </div>
        )

        // if (from == 649) {
        //     return (
        //         <div>
        //             <div className="login-page admin-payment-mode">
        //                 <div className="container">
        //                     <div className="checkout-form border-none">
        //                         <div id="paypal-button-container-P-3AR57177Y1619363XML2SSQQ" className="payment-wrapper" style={{ margin: 'auto' }}></div>
        //                         <PaypalExpressBtn data={'unlimited'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }} ></PaypalExpressBtn>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        // else if (from == 349) {
        //     return (
        //         <div>
        //             <div className="login-page">
        //                 <div className="container">
        //                     <div className="checkout-form border-none">
        //                         <div id="paypal-button-container-P-2GB94113Y3182014CML2SQLI" className="payment-wrapper"></div>
        //                         <PaypalExpressBtn data={'ten'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }} ></PaypalExpressBtn>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }

        // else if (from == 149) {
        //     return (
        //         <div>
        //             <div className="login-page">
        //                 <div className="container">
        //                     <div className="checkout-form border-none">
        //                         <div id="paypal-button-container-P-21761821TN8123130ML2SOXY" className="payment-wrapper"></div>
        //                         <PaypalExpressBtn data={'one'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }}></PaypalExpressBtn>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }


        // else if (from == 9.99) {
        //     return (
        //         <div>
        //             <div className="login-page">
        //                 <div className="container">
        //                     <div className="checkout-form border-none">
        //                         <div id="paypal-button-container-P-21761821TN8123130ML2SOXA" className="payment-wrapper"></div>
        //                         <PaypalExpressBtn data={'3'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }} ></PaypalExpressBtn>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        // else if (from == 7.99) {
        //     return (
        //         <div>
        //             <div className="login-page">
        //                 <div className="container">
        //                     <div className="checkout-form border-none">
        //                         <div id="paypal-button-container-P-21761821TN8123130ML2SOXB" className="payment-wrapper"></div>
        //                         <PaypalExpressBtn data={'2'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }} ></PaypalExpressBtn>

        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }
        // else if (from == 4.99) {
        //     return (
        //         <div>
        //             <div className="login-page">
        //                 <div className="container">
        //                     <div className="checkout-form border-none">
        //                         <div id="paypal-button-container-P-21761821TN8123130ML2SOXC" className="payment-wrapper"></div>
        //                         <PaypalExpressBtn data={'1'} udata={{ firstname, lastname, email, address, phone, city, country: country.name, from, zip, u_email, packType }} ></PaypalExpressBtn>

        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     );
        // }


    }
}