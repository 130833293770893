import { Container, Form, Row, Button, Table } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import { useSelector, useDispatch } from "react-redux";
import { setSolutionscorecard } from "../../Reducer/reducer";
import ReactLoading from "react-loading";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";
import ToolKit from "../../Frontend/Component/ToolKit";

export default function Solutionscorecard() {
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");

  const screenWidth = window.innerWidth

  const [issubmit, setSubmit] = useState(false);
  const [total, setTotal] = useState("");
  const [is_nogo, setNogo] = useState(false);

  const [ps, setProblemScoreCard] = useState(0)
  const [pv, setProblemValidation] = useState(0)
  const [psms, setProblemScoreCardMarketsize] = useState(0)
  const [ca, setCruxalignment] = useState(0)
  const [bm, setbuyermotivation] = useState(0)
  const [psf, setProblemScoreCardFrequency] = useState(0)
  const [r, setRevenue] = useState(0)
  const [sr, setSolutionriskscore] = useState(0)
  const [other, setpmcmother] = useState(0)

  const scoredata = useSelector((state) => state.scorereducer.scorevalue)

  const [state, setState] = useState({
    opportunitywindow: "",
    expectedsolutionlife: "",
    costofservice: "",
    timetoresults: "",
    solutionprovider: "",
    timetomarket: "",
    timetorevenue: "",
    productteamexperience: "",
    researchexperience: "",
    effortforresults: "",
    accesstosolution: "",
    domainexpertise: "",
    reliability: "",
    sustainedcompetitive: "",
    technologysolution: "",
    budgetforbuilding: "",
    techtalent: "",
    teamrecord: "",
    fullcontrol: "",
    total: ""
  });
  const getpmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {

        if (data.problemscorecard.total >= 10) {
          setProblemScoreCard(2)
        } else {
          setProblemScoreCard(- 5)


        }

        if (data.problemvalidationscore.alltotal > 0) {
          setProblemValidation(2)

        } else {
          setProblemValidation(-3)

        }

        if (
          data.problemscorecard.marketsize == 0 ||
          data.problemscorecard.marketsize == 5
        ) {
          setProblemScoreCardMarketsize(1)
        } else {
          setProblemScoreCardMarketsize(-2)
        }

        if (data.cruxalignment.total >= 25) {
          setCruxalignment(4)
        } else {
          setCruxalignment(- 2)
        }

        if (data.buyermotivation.score >= 2) {
          setbuyermotivation(1);
        } else {
          setbuyermotivation(-1)
        }

        if (data.problemscorecard.problemfrequency == 3) {
          setProblemScoreCardFrequency(2)

        } else {
          setProblemScoreCardFrequency(-3)


        }

        if (data.revenuescore.total <= 5) {
          setRevenue(- 3)

        } else {
          setRevenue(1)
        }

        if (data.solutionriskscore.total == 0) {
          setSolutionriskscore(0)


        } else {
          setSolutionriskscore(- 1)

        }

        // console.log(pmcmtotal)

        setpmcmother(data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption)

      }

    });
  };



  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setSolutionscorecard({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
    // alert(is_nogo);


  };

  const setData = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: parseInt(value),
    }));
    getpmcm()
  };


  const totalData = (data) => {
    let sum = 0;
    setNogo(false)

    for (let [key, val] of Object.entries(data)) {
      if (key != "total") {

        // console.log("Scorecard");

        // console.log(key == "opportunitywindow" && val == "0");

        // console.log(key);
        // console.log(val);

        if (key == "opportunitywindow" && val == "0") {
          setNogo(true)
        } else if (key == "expectedsolutionlife" && val == "0") {
          setNogo(true)
        } else if (key == "timetomarket" && val == "2") {
          setNogo(true)
        } else if (key == "technologysolution" && val == "0") {
          setNogo(true)
        } else if (key == "techtalent" && val == "0") {
          setNogo(true)
        }
        if (key == "timetomarket" && val == "2") {

          sum += parseInt(0);

        } else {

          sum += parseInt(val ? val : 0);

        }
        if (key == "requiredData" && val == "-10") {
          setNogo(true)
        }
      }
    }
    return sum;
  };

  let solutionscore = (score) => {
    if (score >= 10) {
      return 2;
    } else {
      return -10;
    }
  };

  let pmcm = (score) => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      console.log(data);
      if (data) {

        let solutionscoreval = solutionscore(score);

        let finaldata = parseInt(ps + pv + psms + ca + bm + psf + r + sr + other) + parseInt(solutionscoreval)

        // console.log(buyerdata)
        setJson(JSON.stringify({ pmcmalignment: { ...data.pmcmalignment, total: parseInt(finaldata) } }), evaluation_id)

        navigate("/user/cruxrectangleboard");

      }
    });
  };


  const backButton = (event) => {
    if (!scoredata) {

      let score = totalData(state)
      setRedux(
        state,
        JSON.stringify({ solutionscorecard: { ...state, total: score }, is_nogo_solution: is_nogo }),
        evaluation_id
      );

      navigate(-1);

    } else {
      navigate(-1);
    }
  };

  const submitData = (event) => {
    if (!scoredata) {

      let score = totalData(state)
      setRedux(
        state,
        JSON.stringify({ solutionscorecard: { ...state, total: score }, is_nogo_solution: is_nogo }),
        evaluation_id
      );

      pmcm(score);

    } else {
      navigate("/user/cruxrectangleboard");
    }
  };

  useEffect(() => {
    setTotal(totalData({ ...state }));
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data.solutionscorecard) {
        setTotal(totalData(data.solutionscorecard));
        setState({ ...data.solutionscorecard });
      }
    }).finally(() => {
      setSubmit(false);
    })
  }, []);

  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  return (
    <div>
      {" "}
      <Header2 />
      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>
            <Row>
              {/* <div className="col-md-12">
                   
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => {
                          submitData(e);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px' }}>
                    Solution Scorecard
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>

                {loader}
                <p>
                  Solution scorecard evaluates effectiveness of your solution to
                  solve the problem. <br />
                  Solution scorecard also enables you to understand many
                  critical aspect of the solution as your are evaluating it
                </p>
              </div>
              <div className="source-table">
                <Table
                  className="table-pd"
                  striped
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <thead>

                    <th style={{ width: "30%", borderTopLeftRadius: '10px', }}>Category</th>
                    <th style={{ width: "20%" }}>Score</th>
                    <th style={{ width: "30%" }}>Category</th>
                    <th style={{ width: "20%", borderTopRightRadius: '10px' }}>Score</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <h2 style={{ display: 'inline' }} >
                          Opportunity Window ( Time available to
                          launch a solution)
                        </h2>
                        <ToolKit text={"How much time do you have to build and launch a solution for your target problem? Select Years or Months accordingly. Sometimes, due to substantial competitive pressures, the market may get flooded with solutions, and you may lose the opportunity. At other times, the life of the problem is short; hence, the opportunity window is brief as well."}
                        />
                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="opportunitywindow"
                            value={state.opportunitywindow}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="0">Months</option>
                            <option value="1">Years</option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >
                          Effort for results(how much effort a customer has to put in to use your solution)
                        </h2>
                        <ToolKit text={'This is a subjective selection. The effort to result (benefit) ratio is judged based on the value delivered by the solution. For comfort and convenience type of solutions, the effort required by the user must be very small, may be filling up a small 4-point data form vs a lifesaving solution which may require a specialized installation by an appointment which could earn an acceptable rating here. Make selection as follows.</br> Outstanding: Select this option if useful results are delivered by the solution with very little effort.</br>Acceptable:Select this option if useful results are delivered by the solution with acceptable effort. </br>Poor: Select this option if useful results are delivered by the solution with more effort.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="effortforresults"
                            value={state.effortforresults}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="2">Outstanding</option>
                            <option value="1">
                              Acceptable
                            </option>
                            <option value="-2">Poor</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >Expected Solution Life</h2>
                        <ToolKit text={'How long will your solution be relevant? There are many factors that decide the life of the solution, one of them is life of the problem itself, other factors are competitive disruptive solutions, or expected changes in regulations, security, compliance that may render the solution ineffective. The regular upgrades needed for the solution are not considered here in deciding the expected solution life. If there are no known factors that limit the life of a solution, select Years.'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="expectedsolutionlife"
                            value={state.expectedsolutionlife}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="0">Months</option>
                            <option value="1">Years</option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >
                          Access to solution (customer on
                          boarding effort)
                        </h2>
                        <ToolKit text={'This again is a subjective evaluation, the users want to provide initial onboarding information in accordance with intensity of the problem they are solving, a key point. Here, you are assessing that balance.Easy: User must provide data easily accessible or known to them, and not private by nature. For e.g. name and email id. OK (Acceptable): The user must provide data that is accessible to him but may require a phone number in addition to email. Hard: Users must provide data that is either private in nature or not readily accessible to them. For e.g. 10-digit account number, or a credit card information. Again, please know that this is a subjective evaluation, asking credit card information upfront for a very low value convenience or entertainment function could be considered “Hard” but asking for a credit card information to book an airline ticket is an “Acceptable” onboarding experience.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="accesstosolution"
                            value={state.accesstosolution}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">Easy</option>
                            <option value="0">Ok</option>
                            <option value="-4">Hard</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >Cost of Service</h2>
                        <ToolKit text={'The choices are low, medium and high. Make selection based on the expected price of the solution and how customers would view it. Also consider what customers are spending in general and what is considered expensive for your country, region and target audience. So, across services in United States, for an average user a $4.99 / month subscription is generally considered low, a $19.99 is considered medium and $49.99 is considered high. However, you also need to apply contextual evaluation here for the problem you are solving and select according to the opinions of most of your customers.'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="costofservice"
                            value={state.costofservice}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="3">Low</option>
                            <option value="1">Med</option>
                            <option value="-2">High</option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >Domain Expertise</h2>
                        <ToolKit text={'Domain expertise is one of the critical factors deciding the outcome of a venture. By domain we mean the market for which the solution is targeted, for example banking, insurance, retail, transportation etc. For e.g. if you are making an application for small business loan division for customer onboarding, and if you have a product owner or a business analyst with 15 years of experience in small business loans you would rate domain expertise as high. Similarly, make your selections.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="domainexpertise"
                            value={state.domainexpertise}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="4">High</option>
                            <option value="1">Med</option>
                            <option value="-7">Low</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >
                          Time to Results (How long it takes for
                          a customer to finish a meaningful
                          action, interaction session time,
                          series of actions )
                        </h2>
                        <ToolKit text={'This rating is about the overall usability of the expected solution. Is it possible to make it outstanding overall? Or, due to a lack of budget and user experience expertise, will your team not be able to deliver it? Note that delivering outstanding experience is an advanced skill; getting to a “Good” time to result will come with consistent effort (usually a couple of years), and if you don’t have experience, “Poor” is the rating you may select. If you are evaluating an existing application, you can select it based on the current state.'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="timetoresults"
                            value={state.timetoresults}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="3">Outstanding</option>
                            <option value="2">Good</option>
                            <option value="-3">Poor</option>
                            <option value="0">Unknown</option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >
                          reliability: How often your solution
                          works
                        </h2>
                        <ToolKit text={'This rating is about the reliability needs of the solution; the more critical function you provide, the higher the reliability needs. 99.99% system reliability translates into 52 minutes of allowable downtime overall in a year. This means that the greater the reliability uptime requirement, the greater the sophistication needed, which translates into higher budget, skills, and time requirements for the solution to be successful. Please select the choices given based on your uptime needs.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="reliability"
                            value={state.reliability}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">99.99</option>
                            <option value="0">
                              {"<99.99"}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >Reputation of Solution Provider</h2>
                        <ToolKit text={'Low, Med, and High. This rating talks about how well-known the entity releasing the solution is. Is it a Microsoft, Amazon, or an Apple of the world? Or is a well-known midsize provider like UiPath or Lucid Chart, or is it an unknown entity? Focus on this from your customer’s point of view, not from the general population; a solution provider may not be known in the general population but may be a highly reputed company in an industry.'} />

                      </td>
                      <td><Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          name="solutionprovider"
                          value={state.solutionprovider}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">
                            -- Select One --
                          </option>
                          <option value="-2">Low</option>
                          <option value="0">Med</option>
                          <option value="2">High</option>
                        </Form.Select>
                      </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >
                          Ability to have sustained Competitive
                          advantage for 5 years
                        </h2>
                        <ToolKit text={'Being able to remain competitive for a period of time is important to have space to establish and get market share. Promising new opportunities attracts many players in the space, and they may outpace you. Any proprietary algorithm, patent, long-term exclusive contracts, or a very difficult-to-make technology could give you a rating of “High”; on the other side, a solution that can be made by several companies quickly would render a “Poor” rating here.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="sustainedcompetitive"
                            value={state.sustainedcompetitive}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="-1">Low</option>
                            <option value="1">Med</option>
                            <option value="4">High</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >
                          Time to Market: How soon can you
                          launch
                        </h2>
                        <ToolKit text={'This rating is about how fast you can finish the solution and launch your first version in the market. Select as follows </br> Opportunity windows > Time to market by 1 year+: You have more than 1 year to build and launch your solution.</br> Opportunity windows > time to market by less than 1 year: You have less than 1 year to launch your solution. </br> Opportunity windows < time to market: The opportunity window is shorter than time to market, which means you may not have time to build the product.'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="timetomarket"
                            value={state.timetomarket}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">
                              {
                                "Opportunity windows > Time to market by 1 year+"
                              }
                            </option>
                            <option value="0">
                              {
                                "Opportunity windows > Time to market by less than 1 year"
                              }
                            </option>
                            <option value="2">
                              {
                                "Opportunity windows > Time to market"
                              }
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >Technology Solution Feasibility</h2>
                        <ToolKit text={'Is the path to solution visible clearly with existing technologies (Yes)? Or your solution will need to invent a new technology (No).  Or you can put together a solution and build first version of the solution, it may have deficiencies (for e.g. in terms of speed of quality) but it will work.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="technologysolution"
                            value={state.technologysolution}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                            <option value="-2">
                              Round About
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >
                          Time to Revenue: How soon can you make
                          your first $
                        </h2>
                        <ToolKit text={'This is about your ability to generate your first dollar by selling the core of your solution to a client, a significant milestone. Is It less than a year or more than one year?'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="timetorevenue"
                            value={state.timetorevenue}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">
                              {" "}
                              {"< 1 Y"}
                            </option>
                            <option value="0">
                              {" "}
                              {"> 1 Y"}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >Tech Talent to build solution</h2>
                        <ToolKit text={'Do you have a technology team or a service provider to be able to build the solution? Confident selection is to be made when the team has done similar solutions before; Experimental selection is to be made when your tech team has general tech experience but has a significant learning curve to master the technology needed for the solution. No selection is to be made when your team cannot learn the skills needed to a level and in a reasonable time.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="techtalent"
                            value={state.techtalent}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">Confident</option>
                            <option value="-1">
                              Experimental
                            </option>
                            <option value="0">No</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >Product Team Experience</h2>
                        <ToolKit text={`This rating is about your team’s experience of building a profitable product in the past; there are only two selections: "Track record of Profitable Product" and “No Experience.” Having a team with prior experience with a successful product is a huge factor, so select that option only if your team has done it before; for anything short of it, select “No Experience.”`} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="productteamexperience"
                            value={state.productteamexperience}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="3">
                              Track Record of Profitable Product
                            </option>
                            <option value="-3">
                              No Experience
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >Team Record</h2>
                        <ToolKit text={'This is the overall record of the team; can they collaborate and work effectively together? Have they delivered successful results before? Select “Success” if the team has delivered a successful solution before by working together, select “No” if the team has no experience working together, and select “Failure” if the team has worked together and was not able to deliver a solution. Remember, this is not about individual tract records of members; this is about their ability to work together.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="teamrecord"
                            value={state.teamrecord}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">Success</option>
                            <option value="-1">No</option>
                            <option value="-4">Failure</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >Research Experience</h2>
                        <ToolKit text={'This rating is about your team’s experience in conducting research. This includes market, customer, competition, and usability research. Select high if the team is proficient in conducting all these research activities, and select medium if you have some of it but know the foundational capabilities or have expertise in at least one that can be ported over to another kind of research. Select “Low” if the team has not done research before.'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="researchexperience"
                            value={state.researchexperience}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="3">High</option>
                            <option value="1">Med</option>
                            <option value="-3">Low</option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        <h2 style={{ display: "inline" }} >Full Control over Tech Stack</h2>
                        <ToolKit text={'This rating is about being able to control or having access to full technology stack – (Yes), meaning you are not dependent on a proprietary technology provided by a third party to provide your solution. Otherwise select No.'}
                          to={screenWidth < 580 ? "left" : 'right'}
                        />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="fullcontrol"
                            value={state.fullcontrol}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="1">Yes</option>
                            <option value="-5">No</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <h2 style={{ display: "inline" }} >Do you have required data?</h2>
                        <ToolKit text={'This rating about having access to the required data to form the core of the solution and being able to deliver the core solution, for applications that depend on data riven insights this is an important dimension. Please select the options as per your solution need.'} />

                      </td>
                      <td>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            disabled={scoredata ? true : false}
                            name="requiredData"
                            value={state.requiredData}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="-2">Yes, but only single source of data</option>
                            <option value="0">Yes, Multiple source of data</option>
                            <option value="3">No External data is needed</option>
                            <option value="-4">Required data is partially available</option>
                            <option value="-10">Required data not available</option>
                          </Form.Select>
                        </Form.Group>
                      </td>

                      <td>
                        {/* <h2 style={{display:"inline"}} >Budget for building solution</h2> */}
                      </td>
                      <td>
                        {/* <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Select
                            name="budgetforbuilding"
                            value={state.budgetforbuilding}
                            onChange={(e) => {
                              setData(e);
                            }}
                          >
                            <option value="">
                              -- Select One --
                            </option>
                            <option value="0">
                              See Funding Score
                            </option>
                          </Form.Select>
                        </Form.Group> */}
                      </td>
                    </tr>
                  </tbody>


                </Table>
                <Table
                  style={{ borderBottomLeftRadius: 10, overflow: 'hidden', borderBottomRightRadius: 10 }}
                >
                  <tfoot>
                    <tr
                    // style={{ borderBottomLeftRadius: '10px' }}
                    >
                      <td className="table-wh" width="50%">
                        <h2 style={{ display: "inline" }} >
                          <b>Total</b>
                        </h2>
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Control
                            type="text"
                            className="form-input-total"
                            readOnly
                            defaultValue={total}
                          />
                        </Form.Group>
                      </td>

                      {/* <td className="table-wh-right" width="50%">
                        <Form.Group
                          className="form-group"
                          controlId="formGridState"
                        >
                          <Form.Control
                            type="text"
                            readOnly
                            defaultValue={total2}
                          />
                        </Form.Group>
                      </td> */}
                    </tr>
                  </tfoot>
                </Table>

              </div>
              <div className="buyer-motivation ">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={backButton}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => {
                          submitData(e);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[10]} />
            </Row>
          </Container>
        </div>
      </div>
    </div >
  );
}
