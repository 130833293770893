import React, { Component } from "react";

//jQuery libraries

import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link, Navigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

import {
  GetInitiative,
  deleteRow,
  newVersion,
  getSteps,
} from "../service/auth";
import axios from "axios";
import Header from "./Component/Header";

class InovationBoardListComponents extends Component {
  constructor(props) {
    super(props);
    this.state = { newData: [], sorting: false };
    this.handleData = this.handleData.bind(this);
  }

  componentDidMount() {
    this.getdata();
  }

  headerdata = () => {
    let token = localStorage.getItem("token");
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  };

  handleData() {
    $(document).ready(function () {
      $("#datatable").DataTable({
        order: [[1, "desc"]],
        bDestroy: true,
        language: {
          emptyTable: "No scorecards created yet",
        },
      });
    });
  }
  getdata = () => {
    const headers = this.headerdata();
    axios
      .get("https://api.gatezerodigital.com/api/getinnovation", headers)
      .then((res) => {
        this.setState({ newData: res.data.data });
        this.handleData();
        this.forceUpdate();
      })
      .catch((err) => console.log(err));
  };
  updateddata = () => {

    const headers = this.headerdata();
    axios
      .get("https://api.gatezerodigital.com/api/getinnovation", headers)
      .then((res) => {

        //console.log(res.data.data)
        this.setState({ newData: res.data.data });
         $("#datatable_ib").DataTable().destroy()

         this.handleData();
        // this.forceUpdate();
      })
      .catch((err) => console.log(err));
  };

  handleData() {
    $(document).ready(function () {
      setTimeout(function () {
        $("#datatable_ib").DataTable({
          aaSorting: [],
          // bSort: false,
          bDestroy: true,
          language: {
            emptyTable: "No scorecards created yet",
          },
        });
      }, 100);
    });
  }

  exportPdf(id) {
    window.location = "/user/results/" + id;
  }

  delete_data = (id) => {
    const headers = this.headerdata();
     axios.delete(
      `https://api.gatezerodigital.com/api/deleteinnovation/${id}`,
      headers
    ).then(()=>{
      // this.getdata();
      this.updateddata()

    }).catch((err) => console.log(err));
  };

  delRowData = async (id) => {
     this.delete_data(id);
  };

  delRow = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h1>Are you sure?</h1>
            <p>
              Are you sure you want to delete this Inovation Board? <br />
              You will not be able recover the Inovation Board once you delete
              it.
            </p>
            <button className="btn btn-danger" onClick={onClose}>
              No
            </button>
            &nbsp;
            <button
              className="btn btn-primary"
              onClick={() => {
                this.delete_data(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  goSteps = async (id) => {
    window.location.href = `/user/innovationboard/${id}`;
  };

  getCAS = (customer, actor, stakeholder) => {
    //console.log(customer , stakeholder , actor);
    if (customer && actor && stakeholder) return "CAS";
    if (customer && actor && !stakeholder) return "CA";
    if (customer  && !actor && !stakeholder) return "C";
    if (actor && stakeholder && !customer) return "AS";
    if (actor && !customer && !stakeholder) return "A";
    if (customer && stakeholder && !actor) return "CS";
    if (stakeholder && !customer && !actor) return "S";
    if (!customer && !actor && !stakeholder) return;
  };

  getEventFrequency = (val) => {
    if (val === "1") return "Daily";
    if (val === "2") return "Weekly";
    if (val === "3") return "Monthly";
    if (val === "4") return "Multiple times a year";
    if (val === "5") return "Yearly";
    if (val === "6") return "Other";
  };

  render() {
    return (
      <>
        <button
          style={{ margin: " 20px 0" }}
          className="dashboard-button"
          onClick={() => {
            $("th.board_score").trigger("click");

            // if(!this.state.sorting){
            //   let arr = this.state.newData.sort((a,b) => b.score - a.score)
            //   this.setState({newData: arr})
            //   this.setState({sorting:true})

            // }
          }}
        >
          Order By Initial Ranking
        </button>

        <div className="MainDiv">
          <div className="container">
            <div className="table-responsive">
              <table
                id="datatable_ib"
                className="table-list cruxcompetitive-table table table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Position</th>
                    <th>Subcategory</th>
                    <th>Transition</th>
                    <th>Market Size</th>
                    <th>Frequency</th>
                    <th>CAS</th>
                    <th>Compelling Event</th>
                    <th>Event Frequency</th>
                    <th>Alert</th>
                    <th className="board_score">Score</th>
                    <th>Create Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.newData.map((result, i) => {
                    return (
                      <tr key={i}>
                        <td>{result.id}</td>
                        <td>{result.title}</td>
                        <td>
                          {result.position === "2"
                            ? "2nd Quadrant"
                            : result.position === "1"
                            ? "1st Quadrant"
                            : result.position === "4"
                            ? "4th Quadrant"
                            : "3rd Quadrant"}
                        </td>
                        <td>{result.subcategory}</td>
                        <td>
                          {result.transition === "1" ? (
                            "Positive"
                          ) : (
                            <>
                              {result.transition === "-2"
                                ? "Negative"
                                : "No Transition"}
                            </>
                          )}
                        </td>
                        <td>
                          {result.market_size === "-4"
                            ? "A Few"
                            : result.market_size === "0"
                            ? "000's"
                            : "Millions"}
                        </td>
                        <td>
                          {result.frequency === "1" ? (
                            "Low"
                          ) : (
                            <>{result.frequency === "2" ? "Medium" : "High"}</>
                          )}
                        </td>
                        <td>
                          {this.getCAS(
                            result.customer,
                            result.actor,
                            result.stakeholders
                          )}
                        </td>
                        <td>
                          {result.compelling_event === "2" ? "Yes" : "No"}
                        </td>
                        <td>
                          {this.getEventFrequency(result.event_frequency)}
                        </td>
                        <td>
                          {result.score < 10 && (
                            <div
                              style={{
                                width: "15px",
                                height: "15px",
                                backgroundColor: "red",
                                borderRadius: "50%",
                              }}
                            ></div>
                          )}
                        </td>
                        <td>{result.score}</td>
                        <td>
                          {moment(result.created_at).format("YYYY-MM-DD")}
                        </td>
                        <td>
                          <ul className="table-icon">
                            <li>
                              <button
                                className="edit"
                                onClick={(e) => this.goSteps(result.id)}
                                title="edit"
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </button>
                            </li>
                            <li>
                              <button
                                className="linkd"
                                onClick={(e) =>
                                  (window.location.href = "/user/initiative")
                                }
                                title="new scorecard"
                              >
                                <i className="fa-solid fa-square-plus"></i>
                              </button>
                            </li>
                            <li>
                              <button
                                className="delete"
                                onClick={(e) => this.delRow(result.id)}
                                title="delete"
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </button>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default InovationBoardListComponents;
