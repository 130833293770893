import React, { Component } from "react";

//jQuery libraries

import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AdminLead, deleteRow, getPaymentsHistory } from "../service/auth";
import { Button, Modal, Alert } from "react-bootstrap";
import axios from "axios";
import dateFormat from "dateformat";

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = { data: [], show: false, num_of_days: 0, msg: "", show_alert: false, user_id: "", varient: "" };
        this.handleData = this.handleData.bind(this);
    }


    handleData() {
        $(document).ready(function () {
            $("#datatable_admim_payment").DataTable({
                order: false,
                bDestroy: true,
                "language": {
                    "emptyTable": "No scorecards created yet"
                }
            })
        });
    }

    componentDidMount() {
        try {
            getPaymentsHistory().then((res) => {
                if (res.data.success) {
                    console.log(res.data.data)
                    this.setState({ data: res.data.data });
                    setTimeout(() => {
                        this.handleData()
                    }, 500)
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    setDate = (date) => {
        let newdate = new Date(date);
        return dateFormat(newdate, 'yy/mm/dd')
    }

    type = (val) => {
        if (val == 'estimation') return "Estimation"
        if (val == 'gatezero') return "GateZero"
        if (val == 'gatezero_free') return "GateZero free"
        if (val == 'estimation_free') return "Estimation free"
    }

    packageType = (type) => {

        if (type == 1) return '$ 4.99'
        if (type == 2) return '$ 7.99'
        if (type == 3) return '$ 9.99'
        if (type == 'one') return '$ 149'
        if (type == 'ten') return '$ 349'
        return '$ 649'
    }

    render() {
        return (
            <>
                <div className="MainDiv">
                    <Modal show={this.state.show} onHide={this.modalClose}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ color: "black", marginLeft: "20px" }}>Extend Trial</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: 10,
                                }}
                            >
                                <div>
                                    <lable style={{ marginRight: "10px", color: "black" }}>Number of days</lable>
                                    <input
                                        type="number"
                                        min="0"
                                        max="1000"
                                        onChange={(e) => { this.setState({ num_of_days: e.target.value }) }}
                                        style={{ marginTop: "10px", fontSize: 16, padding: "5px 10px", borderRadius: "5px", borderColor: "gray", width: "100%" }}
                                    />
                                </div>
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.modalClose()} size="lg">
                                Cancel
                            </Button>
                            <Button variant="dark" size="lg" onClick={() => this.extend_trial()}>Extend Trial</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="container">
                        {this.state.show_alert && <Alert variant={this.state.varient}><div style={{ position: "relative", width: "100%" }}><span>{this.state.msg}</span> <span style={{ cursor: "pointer", position: "absolute", right: 5 }} onClick={() => { this.setState({ msg: "", show_alert: false, variant: "" }) }}>X</span></div></Alert>}
                        <div className="table-responsive">
                            <table
                                id="datatable_admim_payment"
                                className="table-list cruxcompetitive-table table table-striped table-bordered"
                            >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Type</th>
                                        <th>Paid time</th>
                                        <th>Start date</th>
                                        <th>End date</th>
                                        <th>Price</th>
                                        <th>Subscription ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((result, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{result?.user?.name || ""}</td>
                                                    <td>{result?.user?.email || ""}</td>
                                                    <td>{this.type(result?.type || "")}</td>
                                                    <td>{dateFormat(result?.paid_time, 'dd/mm/yy HH:MM:ss')}</td>
                                                    <td>{dateFormat(result?.start_date, 'dd/mm/yy HH:MM')}</td>
                                                    <td>{result?.subscription_ending_at}</td>
                                                    <td>{result?.product_package?.CostInUSD || "Free"}</td>
                                                    <td>{result?.SubscriptionID || ""}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default Payments;
