import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";
import { Logout } from "../../service/auth";
import { useDispatch } from "react-redux";
import { setLogout } from "../../Reducer/reducer";

export default function Header() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [role, setrole] = useState();
  const [dashboard, setDashboard] = useState('inactive')
  const [video, setVideo] = useState('inactive')
  const [subscribe, setSubscription] = useState('inactive')
  const [users, setUsers] = useState('inactive')

  const setFocus = (path) => {
    if (path === "user") {
      setDashboard('active');
      setSubscription('inactive');
      setVideo('inactive');
      setUsers('inactive')

    }
    if (path === "videogalary") {
      setDashboard('inactive');
      setSubscription('inactive');
      setVideo('active');
      setUsers('inactive')

    }
    if (path === "subscribe" || path === "current_subscription" || path === "add_user") {
      setDashboard('inactive');
      setSubscription('active');
      setVideo('inactive');
      setUsers('inactive')

    }
    if (path === "users") {
      setDashboard('inactive');
      setSubscription('inactive');
      setVideo('inactive');
      setUsers('active')
    }
  }

  function SignOut() {
    Logout();
    dispatch(setLogout({
      type: 'Logout'
    }));
    setValidated(true);
  }
  if (validated) {
    navigate("/");
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const isMyTokenExpired = isExpired(token);
    setrole(localStorage.getItem("role"));
    if (isMyTokenExpired) {
      navigate("/");
    }
  }, []);

  let url = window.location.pathname
  useEffect(() => {
    let newurl = url.split("/")
    setFocus(newurl[newurl.length - 1])
  }, [url])

  return (
    <Navbar bg="light" expand="lg" >
      <Container>
        <Navbar.Brand href="/user">
          <img src="../../img/logo.png" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className={video} href="/user/videogalary">Video Library</Nav.Link>
            <Nav.Link className={dashboard} href="/user">Dashboard</Nav.Link>
            <Nav.Link className={subscribe} href="/user/managesubscription/subscribe">Manage Subscription</Nav.Link>
            {role == "admin" ? <Nav.Link className={users} href="/user/users">Users</Nav.Link> : <></>}
            <Nav.Link href="#" onClick={SignOut}>
              Sign Out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
