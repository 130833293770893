import React, { useState, useEffect } from 'react'
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import $ from 'jquery'
export default function Header() {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showNav, setShowNav] = useState(false);

    const [showhome, setHome] = useState("active");
    const [showVideo, setVideo] = useState("inactive");
    const [showAbout, setAbout] = useState("inactive");
    const [showContact, setContact] = useState("inactive");
    // const [showLead , setLead] = useState("inactive");
    const [showPricing, setPricing] = useState("inactive");
    const [out, setOut] = useState(false)

    let width = window.innerWidth;


    const showheaderonhover = (enter) => {
        if (width > 885) {
            if (!out) {
                if (enter) {
                    $('.sub_menu').slideDown('fast');
                } else {
                    setOut(true)
                    $('.sub_menu').slideUp('fast');
                    setTimeout(() => {
                        setOut(false)
                    }, 200)
                }
            }
        }
    }
    const showheaderonclick = () => {
        if (window.innerWidth > 886) {
            return
        }
        $('.sub_menu').slideToggle();
    }


    const showDropdown = (e) => {
        setShow(!show);
        // setShowHeader(true)
        // setHome("inactive");
        // setVideo("inactive");
        // setAbout("inactive");
        // setContact("inactive"); 
        // setPricing("inactive")

    }
    const hideDropdown = e => {
        setShow(false);
    }
    const showNavBar = () => {

        setShowNav(!showNav);
    }


    const moveto = (page_name, hide = false) => {
        // alert(width)

        if (width < 886) {
            if (($(".menu:visible").length)) {
                $('.menu').slideUp()
            }
        }
        if (hide) {
            setTimeout(() => {

                $('.sub_menu').slideToggle()
                // document.querySelector('.sub_menu').style['display'] = 'none'
            }, 100)
        }

        if (page_name == "home" || page_name == "") {
            setHome("active");
            setAbout("inactive");
            setVideo("inactive");
            setContact("inactive");
            setPricing("inactive")
            //setLead("inactive")
            navigate("/");
        } else if (page_name == "video-library") {
            setHome("inactive");
            setVideo("active");
            setAbout("inactive");
            setContact("inactive");
            setPricing("inactive")
            //setLead("inactive")

            navigate("/video-library");
        }
        else if (page_name == "about-us") {
            //alert("move to about us")
            setHome("inactive");
            setVideo("inactive");
            setAbout("active");
            setContact("inactive");
            setPricing("inactive")
            // setLead("inactive")

            navigate("/about-us");


        } else if (page_name == "contact-us") {

            setHome("inactive");
            setVideo("inactive");
            setAbout("inactive");
            setContact("active");
            setPricing("inactive")
            // setLead("inactive")

            navigate("/contact-us");


        } else if (page_name == "signup") {
            setHome("inactive");
            setVideo("inactive");
            setAbout("inactive");
            setContact("inactive");
            setPricing("inactive")
            // setLead("inactive")

            navigate("/signup");


        } else if (page_name == "sign") {

            setHome("inactive");
            setVideo("inactive");
            setAbout("inactive");
            setContact("inactive");
            setPricing("inactive")
            //setLead("inactive")

            navigate("/sign");

        } else if (page_name == "pricing") {

            setHome("inactive");
            setVideo("inactive");
            setAbout("inactive");
            setContact("inactive");
            setPricing("active");
            // setLead("inactive");

            navigate("/pricing");
        }
        // else if(page_name === 'leadgenerate'){
        //     setHome("inactive");
        //     setVideo("inactive");
        //     setAbout("inactive");
        //     setContact("inactive"); 
        //     setPricing("inactive");
        //     setLead("active");
        //     navigate("/leadgenerate");    

        // }

        else if (page_name !== "pricing" || "sign" || "signup" || "contact-us" || "about-us" || "video-library" || "home") {

            setHome("inactive");
            setVideo("inactive");
            setAbout("inactive");
            setContact("inactive");
            setPricing("inactive")
            // setLead("inactive");

        }

        setShowNav(false)

    }

    const togglemenu = () => {
        // $('.bottom-menu-list').slideToggle('fast')
        $('.menu').slideToggle('fast')
    }


    useEffect(() => {

        if (width < 886) {
            document.querySelector('.bottom-menu-list').style['display'] = 'block'
            $('.menu').slideToggle('fast')
        }

    }, [width])

    let url = window.location.href
    useEffect(() => {
        let newurl = url.split("/")
        let currenturl = newurl[newurl.length - 1]
        moveto(currenturl)
    }, [url])


    const hideNavBar = () => setShowNav(false);
    return (
        // <Navbar expand="lg" expanded={showNav}>
        //     <Container>
        //         <Navbar.Brand href="/"><img src="img/logo.png" /></Navbar.Brand>
        //         <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={showNavBar} />
        //             <Navbar.Collapse id="basic-navbar-nav">
        //                 <Nav className="ms-auto">
        //                     <Nav.Link  onClick={() => moveto("home")}  className={showhome} >Home</Nav.Link>
        //                     <NavDropdown className="pr-2 py-lg-4 align-text-top" title="Solutions" id="basic-nav-dropdown"
        //                         show={show}
        //                         onMouseEnter={showDropdown}
        //                         onMouseLeave={hideDropdown}
        //                         onClick={showDropdown}
        //                     >
        //                         <Container>
        //                             <div className="row">
        //                                 <div className="col-md-12">
        //                                     <ul className="rich-out-header">
        //                                         <li>
        //                                             <div className="consulting-reach-header">
        //                                                 <h6>By Services</h6>
        //                                                 <span><a href="/services#financial-gating-solution" onClick={hideNavBar}>Financial Gating Solution</a></span>
        //                                                 <span><a href="/services#product-formulation" onClick={hideNavBar}>Product Formulation</a></span>
        //                                                 <span><a href="/services#marketing-messages" onClick={hideNavBar}>Marketing Messages</a></span>
        //                                                 <span><a href="/services#research" onClick={hideNavBar}>Research</a></span>
        //                                                 <span><a href="/services#user-experience" onClick={hideNavBar}>User experience</a></span>
        //                                                 <span><a href="/services#team-formulation" onClick={hideNavBar}>Team Formulation</a></span>
        //                                                 <span><a href="/services#true-agile" onClick={hideNavBar}>TrueAgile</a></span>
        //                                                 <span><a href="/services#corporate-training" onClick={hideNavBar}>Corporate Training</a></span>
        //                                             </div>
        //                                         </li>
        //                                         <li className="">
        //                                             <div className="consulting-reach-header">
        //                                                 <h6>By Pain Point</h6>
        //                                                 <span>
        //                                                     <a href="/pain-points#am-i-building-or-investing-in-the-right-product">
        //                                                         Am I building or investing in the right product?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#how-can-i-reduce-complexity-and-improve-time-to-market">
        //                                                         How can I reduce complexity and improve time to market?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#where-exactly-is-my-customer">
        //                                                         Where exactly is my customer?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#which-new-product-will-make-me-the-market-leader">
        //                                                         Which new product will make me the market leader?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#what-do-i-need-to-change-in-my-existing-products">
        //                                                         What do I need to change in my existing products?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#why-is-my-marketing-not-working">
        //                                                         Why is my marketing not working?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#how-to-reach-and-engage-my-customers">
        //                                                         How to reach and engage my customers?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#what-kind-of-research-and-analysis-do-i-need">
        //                                                         What kind of research and analysis do I need?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#how-do-i-ensure-adoption-and-growth-of-my-product">
        //                                                         How do I ensure adoption & growth of my product?
        //                                                     </a>
        //                                                 </span>
        //                                                 <span>
        //                                                     <a href="/pain-points#what-kind-of-team-do-i-need-to-execute-the-vision">
        //                                                         What kind of team do I need to execute the vision?
        //                                                     </a>
        //                                                 </span>
        //                                             </div>
        //                                         </li>
        //                                         <li>
        //                                             <div className="consulting-reach-header">
        //                                                 <h6>By Roles</h6>
        //                                                 <span><a href="/roles#founders-and-ceos">Founders & CEOs</a></span>
        //                                                 <span><a href="/roles#venture-capitalists">Venture Capitalists</a></span>
        //                                                 <span><a href="/roles#executive-leadership">Executive Leadership</a></span>
        //                                                 <span><a href="/roles#angle-investors">Angel Investors</a></span>
        //                                                 <span><a href="/roles#entrepreneurs">Entrepreneurs</a></span>
        //                                                 <span><a href="/roles#product-owners">Product Owners</a></span>
        //                                             </div>
        //                                         </li>
        //                                     </ul>
        //                                 </div>
        //                             </div>
        //                         </Container>
        //                     </NavDropdown>

        //                     <Nav.Link  onClick={() =>moveto("video-library")}  className={showVideo} >Video Library</Nav.Link>
        //                     <Nav.Link  onClick={() => window.open("https://blog.gatezerodigital.com/")} >Blogs</Nav.Link>
        //                     <Nav.Link  onClick={() =>moveto("about-us")} className={showAbout}>About Us</Nav.Link>
        //                     <Nav.Link  onClick={() =>moveto("contact-us")} className={showContact}>Contact Us</Nav.Link>
        //                     <Nav.Link  onClick={() =>moveto("pricing")} className={showPricing}>Pricing</Nav.Link>
        //                     {/* <Nav.Link  onClick={() =>moveto("leadgenerate")} className={showLead}>Lead Generate</Nav.Link> */}
        //                     <Nav.Link  onClick={() =>moveto("signup")}> <a className="sign-btn">Sign Up</a>{' '}</Nav.Link>
        //                     <Nav.Link  onClick={() =>moveto("sign")}> <Button className="btn-primary-2">Sign In</Button>{' '}</Nav.Link>
        //                 </Nav>
        //             </Navbar.Collapse>
        //     </Container>
        // </Navbar>
        <div id="myHeader" class="header-section">
            <div class="container">
                <div class="header-wrapper">
                    <div class="header-logo" style={{ cursor: 'pointer' }} onClick={() => moveto("home")} >
                        <img src="/img/images/top-logo.png" alt />
                    </div>
                    <section class="bottom-navbar-homepage">
                        <div class="bottom-navbar-block-homepage">
                            <div class="bottom-navbar-section-homepage">
                                <div class="bottom-container">
                                    <div onClick={togglemenu} class="real-menu-toogle">
                                        <i class="fa fa-bars" aria-hidden="true"></i>
                                    </div>
                                    <div class="bottom-menu-list">
                                        <div class="menu">
                                            <ul>
                                                <li><div className='header-item' onClick={() => moveto("home")} >Home</div></li>
                                                <li class="active" onClick={() => showheaderonclick()} onMouseEnter={() => showheaderonhover(true)} onMouseLeave={() => showheaderonhover(false)}><div className='header-item' href="#">Solutions <i class="fa fa-angle-down" aria-hidden="true" style={{ fontSize: 13.5 }} ></i> </div>
                                                    <div class="sub_menu" style={{ maxHeight: width > 885 ? window.innerHeight - 66 : 'auto', overflowY: 'auto' }}>
                                                        <h3>Leaders: Put brakes on bad technology investments, identify your best opportunities and unlock 10 high value benefits.</h3>
                                                        <p>GateZero is for CEOs, CIOs, CFOs, V-level leaders, startups and entrepreneurs, Venture capitalist and angel investors, product owners and revenue officers</p>
                                                        <div class="drop_down_col">
                                                            <div class="drop_box">
                                                                <h2>Critical answers leaders need</h2>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        {/* <img src="img/images/arrow_img.png" /> */}
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <h2> Should I invest in this initiative?</h2>
                                                                        <p>Predict the outcome of your technology innovation BEFORE you invest.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="./img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <h2>How much revenue can I expect and when?</h2>
                                                                        <p>Gain clarity on revenue potential and timelines.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <h2>How do I create innovation for my business?</h2>
                                                                        <p>Master the principles of disruption to create groundbreaking products.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <h2>How do I change my existing products for growth?</h2>
                                                                        <p>Pivot existing products for massive adoption and growth.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <h2>What should be the message to the customers?</h2>
                                                                        <p>Craft precise marketing messages and compelling product narratives.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="drop_box">
                                                                <h2>GateZero Solutions</h2>
                                                                <div class="drop_cont">
                                                                    <div class="pic_text">
                                                                        <h2>GateZero Precision Invest 360 – Suite of 16 research-led assessment & pivot tools</h2>
                                                                        <p>These unique tools evaluate 135 critical data points encompassing problem rationale, product-market fit, technology solutions, marketing, risks, funding, and more. Built on six years of rigorous research and the analysis of thousands of technology initiatives, GateZero provides unparalleled visibility into your projects, identifying weaknesses and offering actionable improvements & pivots. Ensure your technology investments are informed, strategic, and poised for success with GateZero.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_text">
                                                                        <h2>Comprehensive Training Solutions</h2>
                                                                        <p>Access both self-service and instructor-led corporate training to empower your team with the knowledge and skills needed to maximize the value of technology investments.</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_text">
                                                                        <h2>GateZero EstimateMe@</h2>
                                                                        <p>Utilize the world's leading effort estimation tool to generate highly accurate time and cost estimates,ensuring informed decision-making and optimized resource allocation.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="drop_box box_3">
                                                                <h2>GateZero Business Benefits</h2>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Predict investment outcomes</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Ensure technology innovation success</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Prevent costly failures and save millions</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Reduce IT costs by 12-25%</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Identify massive opportunities, set accurate priorities, and invest with confidence</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Create disruptive products, differentiation, and ensure customer adoption</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Unlock explosive growth, and maximize returns</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Enhance operational efficiency and productivity</p>
                                                                    </div>
                                                                </div>
                                                                <div class="drop_cont">
                                                                    <div class="pic_arrow">
                                                                        <img src="img/images/arrow_img.png" />
                                                                    </div>
                                                                    <div class="pic_text">
                                                                        <p>Achieve strategic alignment with business objectives</p>
                                                                    </div>
                                                                </div>
                                                                <div class="btn_wrap">
                                                                    <a href="#" onClick={() => moveto("signup", true)} >Signup for free trial – Get your answers now</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="our_links">
                                                            <div><a>SaaS based</a></div>
                                                            <div><a>Risk-free</a></div>
                                                            <div><a>Self-service</a></div>
                                                            <div><a>High ROI</a></div>
                                                            <div><a>Fast</a></div>
                                                            <div><a>Easy</a></div>
                                                            <div><a>Accurate</a></div>
                                                            <div><a>Built for all</a></div>
                                                            <div><a>Trusted</a></div>
                                                            <div><a>Proven</a></div>
                                                            <div><a>Easy to test</a></div>
                                                            <div><a>Differentiating</a></div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><div href="#" className='header-item' onClick={() => moveto("video-library")}>Video Library</div></li>
                                                {/* <li><div href="#" className='header-item' onClick={() => window.open("https://blog.gatezerodigital.com/")}>Blogs</div></li> */}
                                                <li><div href="#"
                                                    onClick={() => moveto("about-us")}
                                                    className='header-item'>About us</div></li>
                                                <li><div href="#"
                                                    onClick={() => moveto("contact-us")}
                                                    className='header-item'>Contact Us</div></li>
                                                <li><div href="#"
                                                    onClick={() => moveto("pricing")}
                                                    className='header-item'>Pricing</div></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="login-btns">
                        <div className='btns sign_up' href="#" onClick={() => moveto("signup")}>Sign Up</div>
                        <div className='btns sign_in' href="#" onClick={() => moveto("sign")}>Sign In</div>
                    </div>
                </div>
            </div >
        </div >
    )
}