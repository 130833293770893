import React, { useState, useEffect } from "react";
import Datepicker2 from "./Datepicker2";
import { Container, Row, Button, Table } from "react-bootstrap";
import Header from "./Component/Header";
import AdminListComponent from "./AdminListComponent";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AdminLeadGenerate from './AdminLeadGenerate'
import Payments from "./Payments";

export default function Admindashboard() {


  let Adminlist;
  let AdminLead;
  let paymentsTable

  const [date, setDate] = useState({ fromdate: "", todate: "" });

  const [access , setAccess] = useState(false)
  const [password , setPassword] = useState("")

  const pass = process.env.REACT_APP_ADMIN


  const login = () => {
    if(password == pass ){
      localStorage.setItem("admin" , "true")
      setAccess(true)
    }
  }

  useEffect(()=>{
    let admin = localStorage.getItem('admin')

    if(admin){
      setAccess(true)

    }else{
      setAccess(false)
    }
  },[])
  
  if(date){
    Adminlist=<AdminListComponent date={date} />;
    AdminLead=<AdminLeadGenerate data={date} />;
    paymentsTable = <Payments/> 
  }

  return (
    <div>
      <Header />
      <div className="Admin-dashboard" style={{height: access ? "auto" : "100vh"}}>
        {!access ?
      <Container>
            <div className="admin-deshbord-title" style={{marginTop:"20px"}}>
            <h2>Admin dashboard</h2>
            </div>
            <div style={{textAlign: "center"}}>

        <Form noValidate onSubmit={login}   >
              <Form.Group
                className="mb-4 password"
                controlId="formBasicPassword"
                >
                <Form.Label className="text-dark">Password</Form.Label>
                <Form.Control
                style={{fontSize:"18px" , width:"20vw" , margin: "0 auto"}}
                  required
                  type="password"
                  placeholder="********"
                  maxLength="12"
                  autoComplete="off"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  />
                <Form.Control.Feedback></Form.Control.Feedback>
                <span class="check-aero">
                  <i class="fa-solid fa-circle-check"></i>
                </span>
              </Form.Group>
              <Button type="submit" variant="primary">
                  Submit
                </Button>
              </Form>
                  </div>
        </Container>
        :
        <Container>
          <div className="admin-deshbord-title"  style={{marginTop:"20px"}}>
            <h2>Admin dashboard</h2>
            <Datepicker2 date={setDate} />
          </div>
          <div className="table-list Problemvalidationtable" style={{marginBottom:"40px"}}>
            {Adminlist}
          </div>
          <div className="admin-deshbord-title">
            <h2 >Lead Generating</h2>
          </div>
          <div className="table-list Problemvalidationtable" style={{marginBottom:"40px"}}>
            {AdminLead}
          </div>
          <div className="admin-deshbord-title">
            <h2 >Payments History</h2>
          </div>
          <div className="table-list Problemvalidationtable">
            {paymentsTable}
          </div>
        </Container>}
      </div>
    </div>
  );
}
