import React, { useEffect, useReducer, useState, useRef } from "react";

const ToolKit = (props) => {

    const { text, to = 'right' } = props;
    const [activeToolTip, setActivetooltip] = useState(false);

    const tooltipref = useRef(null);


    const showToolTip = (activetooltip) => {

        setTimeout(() => {
            if (activeToolTip == false || activeToolTip != activetooltip) {
                setActivetooltip(activetooltip);
            }
            else {
                setActivetooltip(false);
            }
        }, 100)
    }


    const handleClickOutside = (event) => {
        if (event.target.className == "tool-kit-content") {
        }
        else if (tooltipref.current && !tooltipref.current.contains(event.target)) {
            showToolTip(false);
        }
    };

    useEffect(() => {

        if (activeToolTip != false) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [activeToolTip])

    return (
        <div className="toolkit-container" >
            <div className="tool-kit" onClick={() => showToolTip(!activeToolTip)}>
                {activeToolTip && <div ref={tooltipref} onClick={(e) => e.stopPropagation()} className={`tool-kit-content-${to}`} dangerouslySetInnerHTML={{ __html: `<div>${text}</div>` }} ></div>}
            </div>
        </div>
    )

}

export default ToolKit