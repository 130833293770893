import { Container, Form, Row, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import { useSelector, useDispatch } from "react-redux";
import ReactLoading from "react-loading";
import { setDimentional } from "../../Reducer/reducer";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";
import ToolKit from "../../Frontend/Component/ToolKit";

var CryptoJS = require("crypto-js");

export default function Probdesc() {
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");

  const screenWidth = window.innerWidth

  const [problem, setProblem] = useState("");
  const [situationExperience, setSituationExperience] = useState("");
  const [situationFacing, setSituationFacing] = useState("");
  const [activitydone, setActivityDone] = useState("");
  const [primaryroot, setPrimaryRootCause] = useState("");
  const [idealoutcome, setIdealOutCome] = useState("");
  const [proposedsolution, setProposedSolution] = useState("");
  const [solutionidealoutcome, setSolutionIdealOutcome] = useState("");
  const [situation, setSituation] = useState("");

  const [issubmit, setSubmit] = useState(false);

  const data = useSelector((state) => state.scorereducer.scorevalue)

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setDimentional({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const submitData = (event) => {
    if (!data) {

      event.preventDefault();
      event.stopPropagation();

      var encrypt_problem = CryptoJS.AES.encrypt(JSON.stringify(problem), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_situationExperience = CryptoJS.AES.encrypt(JSON.stringify(situationExperience), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_situationFacing = CryptoJS.AES.encrypt(JSON.stringify(situationFacing), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_activitydone = CryptoJS.AES.encrypt(JSON.stringify(activitydone), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_primaryroot = CryptoJS.AES.encrypt(JSON.stringify(primaryroot), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_idealoutcome = CryptoJS.AES.encrypt(JSON.stringify(idealoutcome), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_proposedsolution = CryptoJS.AES.encrypt(JSON.stringify(proposedsolution), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_solutionidealoutcome = CryptoJS.AES.encrypt(JSON.stringify(solutionidealoutcome), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();
      var encrypt_situation = CryptoJS.AES.encrypt(JSON.stringify(situation), '57e4460f-f355-4920-97ba-7ff89095bfbf').toString();


      let data = {
        encrypt_problem,
        encrypt_situationExperience,
        encrypt_situationFacing,
        encrypt_activitydone,
        encrypt_primaryroot,
        encrypt_idealoutcome,
        encrypt_proposedsolution,
        encrypt_solutionidealoutcome,
        encrypt_situation,
      };

      setRedux(data, JSON.stringify({ dimentionalProblem: data }), evaluation_id);
      navigate("/user/customeractor");
    } else {
      navigate("/user/customeractor");
    };
  }
  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data.dimentionalProblem) {

        //console.log("problem")
        //console.log(data.dimentionalProblem.encrypt_problem)

        var problem = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_problem, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var situationExperience = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_situationExperience, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var situationFacing = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_situationFacing, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var activitydone = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_activitydone, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var primaryroot = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_primaryroot, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var idealoutcome = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_idealoutcome, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var proposedsolution = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_proposedsolution, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var solutionidealoutcome = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_solutionidealoutcome, '57e4460f-f355-4920-97ba-7ff89095bfbf');
        var situation = CryptoJS.AES.decrypt(data.dimentionalProblem.encrypt_situation, '57e4460f-f355-4920-97ba-7ff89095bfbf');


        setProblem(JSON.parse(problem.toString(CryptoJS.enc.Utf8)));
        setSituationExperience(JSON.parse(situationExperience.toString(CryptoJS.enc.Utf8)));
        setSituationFacing(JSON.parse(situationFacing.toString(CryptoJS.enc.Utf8)));
        setActivityDone(JSON.parse(activitydone.toString(CryptoJS.enc.Utf8)));
        setPrimaryRootCause(JSON.parse(primaryroot.toString(CryptoJS.enc.Utf8)));
        setIdealOutCome(JSON.parse(idealoutcome.toString(CryptoJS.enc.Utf8)));
        setProposedSolution(JSON.parse(proposedsolution.toString(CryptoJS.enc.Utf8)));
        setSolutionIdealOutcome(JSON.parse(solutionidealoutcome.toString(CryptoJS.enc.Utf8)));
        setSituation(JSON.parse(situation.toString(CryptoJS.enc.Utf8)));
      }
    }).finally(() => {
      setSubmit(false);

    })
  }, []);

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  return (
    <div>
      <Header2 />
      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>

            <Row>

              {/* <div className="col-md-12">
                <div className="next-back-button probl">   
                {loader}     
                  <Button
                    variant="primary button-blue"
                    onClick={(e) => submitData(e)}
                  >
                    Next
                  </Button>
                </div>
              </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px' }}>
                    8-Dimentional Problem Definition
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>

                {loader}
                <p>
                  Use this canvas to improve your understanding of the problem
                  your initiative is attempting to solve. Also, use this canvas
                  to create consensus amongst the team member on the rationale
                  of the product.
                </p>
                <h5>
                  All data is encrypted in the database and in transit. No one
                  else can access your data. Your privacy is guaranteed.
                </h5>
              </div>
              <div className="dimentional-list">
                <div className="row">
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label" >Problem</div>
                      <ToolKit text={"What is the problem being solved? If you see a need, drive it down to the problem that is giving rise to it. Be as detailed as possible. Note down everything you know about the problem."} />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={problem}
                          onChange={(e) => setProblem(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label" >Situation(s) in which the problem is experienced</div>
                      <ToolKit text={'Note locations, people, geographical areas, weather, noise levels, physical space, cultural relevance, time, and financial constraints of the situation. Note down the technology availability, such as the internet connection, Wi-Fi, etc. Understanding the situation is extremely important in order to create a great customer experience and a successful solution.'}
                        to={screenWidth < 348 ? "right" : screenWidth < 500 ? "left" : screenWidth < 635 ? "center" : 'right'}
                      />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={situationExperience}
                          onChange={(e) =>
                            setSituationExperience(e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label">Who in the situation is facing the problem?</div>
                      <ToolKit text={"Focus on the user facing the problem and how important and urgent it is for the user to solve the problem. What are the user's means and other constraints? Also, note the demographics, psychographics, and social and cultural preferences of the customer and customer groups."}
                        to={screenWidth < 500 ? "left" : screenWidth < 635 ? "center" : screenWidth < 1450 ? "left" : 'right'} />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={situationFacing}
                          onChange={(e) => setSituationFacing(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4">
                      <div className="label">Which activity is being done which includes the problem?</div>
                      <ToolKit text={"Focus on the activity that includes the problem and describe the activity in as much detail as possible. What are the key drivers of efficiencies and known KPIs of that activity? Also, note down surrounding activities and workflows of which this activity is part. Describe things in detail. What happens before and after the activity, what kind of data is needed to carry out the activity, what data can improve the activity, note these details."}
                        to={screenWidth < 390 ? 'right' : screenWidth < 500 ? "left" : screenWidth < 635 ? "center" : 'right'}
                      />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={activitydone}
                          onChange={(e) => setActivityDone(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label">What are the primary root causes of the problem?</div>
                      <ToolKit text={'Focus on the root causes of the problem, note down in detail, also list down factors affecting the root causes.'}
                        to={screenWidth < 358 ? "right" : screenWidth < 500 ? "left" : screenWidth < 635 ? "center" : 'right'}
                      />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={primaryroot}
                          onChange={(e) => setPrimaryRootCause(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label" >What is the ideal outcome?</div>
                      <ToolKit text={"Note down the ideal outcome from the user’s perspective when the problem is solved, focus on situations and activities too. Note down primary and secondary ideal outcomes. Secondary ideal outcomes can be used to enhance the product later in the roadmap and can help in creating differentiations."}
                        to={screenWidth < 635 ? "center" : screenWidth < 1450 ? "left" : 'right'}
                      />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={idealoutcome}
                          onChange={(e) => setIdealOutCome(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label">What is the proposed solution?</div>
                      <ToolKit text={"Note down the solution, what the solution is being envisioned, how it works, and what the preconditions are. Note down the time, resources, requirements, non-functional requirements, and skills needed to create such a solution, and list all the details you know about the solution at this time."}
                        to={screenWidth < 635 ? "center" : 'right'}
                      />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={proposedsolution}
                          onChange={(e) => setProposedSolution(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-4" >
                      <div className="label">How does the proposed solution cause ideal outcome?</div>
                      <ToolKit text={"Describe and build rationale of how the proposed solution is creating the ideal outcome, this is the most important step, 60 to 70% of technology product failures have the root cause buried here. Describe I detail, also describe preconditions and what are the performance criteria from the user’s perspective."}
                        to={screenWidth < 390 ? 'right' : screenWidth < 500 ? "left" : screenWidth < 635 ? "center" : 'right'}
                      />
                    </div>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                          readOnly={data}
                          as="textarea"
                          defaultValue={solutionidealoutcome}
                          onChange={(e) =>
                            setSolutionIdealOutcome(e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <label>What is the Situation?</label>
                    <div className="dimentional-box">
                      <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                        readOnly={data}
                          as="textarea"
                          defaultValue={situation}
                          onChange={(e) =>
                            setSituation(e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="next-back-button">
                      {loader}
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[1, 2, 3, 4]} />

            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
