import { blueGrey, yellow } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import $ from "jquery"

export default function Home() {

    const navigate = useNavigate();
    const [customText, setCustomText] = useState({ title: 'Prevent major technology failures,', titleyellow: " ensure success and massive growth", subText: 'Financial gating & product formulation solution for digital investments' })

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        let title = query.get('1') || null
        let titleyellow = query.get('2') || null
        let sub = query.get('3') || null

        let data = {
            1: title,
            2: titleyellow,
            3: sub
        }

        let URLData
        if (!title && !titleyellow && !sub) {
            let _URLData = sessionStorage.getItem('urldata')
            if (_URLData) {
                URLData = JSON.parse(_URLData)
                window.history.pushState('', '', `/?${URLData?.[1] ? "1=" + URLData[1] : ""}${URLData?.[2] ? "&2=" + URLData?.[2] : ""}${URLData?.[3] ? "&3=" + URLData?.[3] : ""}`)
            }
        } else {
            sessionStorage.setItem('urldata', JSON.stringify(data))
        }

        setCustomText({
            title: title || URLData?.[1] || customText.title,
            titleyellow: titleyellow || URLData?.[2] || customText.titleyellow,
            subText: sub || URLData?.[3] || customText.subText
        })
    }, [window.location.search])

    return (
        <>

            <div class="hero-banner">
                <div class="hero-img banner-wrapper"
                    style={{ backgroundImage: "url('./img/images/banr-back.jpg')" }}>
                    <div class="hero-container">
                        <div class="baner-text" data-aos="fade-right" data-aos-duration="1000">
                            <h1>{customText.title}<span>{customText.titleyellow}</span></h1>
                            <p>{customText.subText}</p>
                            {/* <h1>Prevent major <br /> technology failures, <span>ensure success and massive growth</span></h1>
                            <p>Financial gating & product formulation solution for digital investments</p> */}
                            <div class="btn_wrap">
                                <a href="#" onClick={() => navigate("/signup")}>Sign Up</a>
                            </div>
                        </div>
                        <div class="banr-im-right" data-aos="fade-left" data-aos-duration="1000"><img src="img/images/banner-img-right.png" /></div>
                    </div>
                </div>
            </div>

            {/* <!-- platform section --> */}
            <div class="platform_sec" >
                <div class="container">
                    <div class="platform_row" data-aos="fade-up"
                        data-aos-duration="800">
                        <div class="platform_im">
                            <img class="desk" src="img/images/platform.png" />
                            <img class="mob" src="img/images/platform-mob.png" />
                        </div>
                        <div class="platform_text">
                            <h1 class="tittle_main">GateZero is a technology <br /> investment assessment platform</h1>

                            <div class="sub_text">
                                <h2 class="">CIOs</h2>
                                <p>70% of all technology initiatives fail to deliver business value, and 31% are canceled before completion, costing companies  ~US $150 billion a year.</p>
                            </div>

                            <div class="sub_text">
                                <h2 class="">Ensure your investments succeed, risks are managed, and growth is guaranteed with GateZero.</h2>
                                <p>GateZero, built on six years of rigorous research, is extremely effective at preventing bad digital investments right upfront, saving you millions of dollars and years of time.</p>

                                <p>Ensure your technology investments are informed, strategic, and poised for success with GateZero.</p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- platform section end  --> */}

            {/* <!-- questions section --> */}
            <div class="questions_sec" >
                <div class="banner-wrapper"
                    style={{ backgroundImage: "url('img/images/qustion-bner.jpg')" }}>
                    <div class="container">
                        <div class="questions_text" data-aos="fade-up"
                            data-aos-duration="800">
                            <h1 class="tittle_main">GateZero answers 10 critical questions <br /> confronting the leaders</h1>
                            <ul class="list_text">
                                <li>Should I invest in this initiative? </li>

                                <li>How can I maximize my technology budget for maximum ROI? </li>

                                <li>How much revenue can I expect from my technology investments, and when? </li>

                                <li>What are the high-value opportunities? </li>

                                <li>How do I drive growth and innovation for the business? </li>

                                <li>How do I upskill my product teams to build disruptive products? </li>

                                <li>How do I change (pivot) my existing products for growth? </li>

                                <li>How do I quickly source ideas and pick the best ones? </li>

                                <li>How do I ensure technology initiatives align with business goals? </li>

                                <li>What should be the message to the customers? </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- questions section end  --> */}

            <div class="Invest_sec" data-aos="fade-up"
                data-aos-duration="800">

                <div class="container">
                    <div class="Invest_wrap">
                        <div class="Invest_text">
                            <h1 class="tittle_main">The GateZero Precision Invest 360</h1>
                            <div class="sub_text">
                                <h2 class="">A suite of 16 research-led assessment & pivot tools & <br /> online scorecards provide the critical answers you seek. </h2>
                            </div>
                            <div class="invest_list">
                                <ul class="list_text">
                                    <li>8-Dimensional Problem Definition tool </li>


                                    <li>Customer-Actor-Stakeholder tool</li>


                                    <li>Buyer Motivation Parity tool</li>


                                    <li>Problem Validation and Problem Score tool</li>


                                    <li>Purchase Decision Alignment tool</li>

                                    <li>Revenue Potential Assessment tool</li>

                                    <li>Marketing Capability Assessment</li>

                                    <li>Product Market Channel Model</li>

                                    <li>CRUX-Competitive Analysis & tool</li>

                                </ul>

                                <ul class="list_text">

                                    <li>CRUX Rectangle </li>

                                    <li>CRUX-Board tool</li>

                                    <li>CRUX-Impact tool</li>

                                    <li>CRUX-Alignment tool</li>

                                    <li>Demand Peak tool</li>

                                    <li>Solution Scorecard</li>

                                    <li>Solution Risk Assessment</li>

                                    <li>Funding Assessment tool</li>

                                    <li>Interpretation of final score</li>
                                </ul>
                            </div>
                        </div>
                        <div class="Invest_img">
                            <img src="img/images/360-img.jpg" />
                        </div>

                    </div>
                </div>
            </div>

            <section class="feature-one" data-aos="fade-up"
                data-aos-duration="800">
                <div class="container">
                    <h1 class="tittle_main">Evaluate opportunity - Can you execute it? - Is it for you? <br /> What you need to build? - how to pivot? </h1>
                    <div class="feature-row">
                        <div class="wow fadeInLeft animated">
                            <div class="feature-one__single">

                                <div class="feature-one__icon">
                                    <span class="icon-protection">
                                        <img class="out-hvr" src="img/images/score.png" />
                                        <img class="in-hvr" src="img/images/score-hvr.png" />
                                    </span>
                                </div>
                                <div class="feature-one__content">
                                    <h3>OPPORTUNITY SCORE</h3>
                                    <p class="feature-one__sub-title">Know if the problem you are trying to solve is worth it? Will it generate revenue, when and how much? Do you have the right product rationale? Eliminate seeds of failures, know your risk and fight the right battle.</p>

                                </div>
                            </div>
                        </div>
                        <div class="wow fadeInUp animated" >
                            <div class="feature-one__single">

                                <div class="feature-one__icon">
                                    <span class="icon-investment">
                                        <img class="out-hvr" src="img/images/score-2.png" />
                                        <img class="in-hvr" src="img/images/score-hvr-2.png" />
                                    </span>
                                </div>
                                <div class="feature-one__content">
                                    <h3>EXECUTION SCORE</h3>
                                    <p class="feature-one__sub-title">Know if you can execute on the opportunity based on your unique situation, your resources, teams and expertise. A comprehensive evaluation, pin points areas of improvement for rapid and successful execution.</p>
                                </div>
                            </div>
                        </div>
                        <div class="wow fadeInRight animated" >
                            <div class="feature-one__single">

                                <div class="feature-one__icon">
                                    <span class="icon-risk-management">
                                        <img class="out-hvr" src="img/images/score-3.png" />
                                        <img class="in-hvr" src="img/images/score-hvr-3.png" />
                                    </span>
                                </div>
                                <div class="feature-one__content">
                                    <h3>PRODUCT FORMULATION</h3>
                                    <p class="feature-one__sub-title">Know what exactly you need to build to please the customers and defeat the competition. Know how to pivot your product to turn around stuck initiatives. Land undefeatable products.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section>
                <div class="video_sec" data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="container">
                        <div class="video_row">
                            <div class="benefits_list">
                                <h1 class="tittle">GateZero delivers high-impact, <br /> transformative benefits.</h1>
                                <ul class="list_text">
                                    <li>Predict investment outcomes</li>
                                    <li>Ensure technology innovation success</li>
                                    <li>Prevent costly failures and save millions</li>
                                    <li> Reduce IT costs by 12-25%</li>
                                    <li>Identify massive opportunities, and invest confidently</li>
                                    <li>Ensure stakeholder support, and secure budgets </li>
                                    <li>Set accurate priorities and demonstrate value</li>
                                    <li>Create disruptive products and differentiation.</li>
                                    <li>Ensure customer adoption.</li>
                                    <li>Unlock explosive growth and maximize returns</li>
                                    <li>Enhance operational efficiencies and productivity</li>
                                    <li>Improve customer satisfaction and retention</li>
                                    <li>Achieve strategic alignment with business objectives</li>

                                </ul>
                            </div>

                            <div class="video">
                                <iframe
                                    class="embed-responsive-item"
                                    src={"https://www.youtube.com/embed/rFG4KKkrVjc?controls=0&autoplay=1&loop=1&&rel=0"}
                                    width="100%"
                                    height="100%"
                                ></iframe>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <section>
                <div class="You_are_row" data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="colm">
                        <div class="benefits_list answers_now">
                            <h1 class="tittle_main">You're fully empowered. Get your answers now. </h1>
                            <ul class="list_text">
                                <li>Get instant free access</li>
                                <li>Full product trial</li>
                                <li>Self-service videos  </li>
                                <li>No upfront investment</li>
                                <li>Secure & confidential </li>
                                <li>Test GateZero with any of your previous initiatives. See how accurate it is.</li>

                            </ul>
                            <div class="btn_wrap">
                                <a href="#" onClick={() => navigate("/signup")}  > Start here</a>
                            </div>
                        </div>
                    </div>
                    <div class="colm portfolio">
                        <div class="benefits_list">
                            <h1 class="tittle_main">Evaluate an initiative or your entire portfolio.</h1>
                            <ul class="list_text">
                                <li>SaaS based</li>
                                <li>Risk-free</li>
                                <li>Self-service</li>
                                <li>Fast</li>
                                <li>High ROI</li>
                                <li>Easy</li>
                                <li>Accurate</li>
                                <li>Built for all</li>
                                <li>Trusted </li>
                                <li>Proven</li>
                                <li>Easy to test</li>
                                <li>Differentiating</li>

                            </ul>
                        </div>
                    </div>
                </div>
            </section >


            <section>

                <div class="Typical-sec" data-aos="zoom-in" data-aos-duration="800">
                    <div class="container">
                        <h1 class="tittle_main">Typical GateZero adoption roadmap</h1>
                        <h3 class="tittle_small">Start Small - Transform Big</h3>
                        <div class="Start_Small" >
                            <div class="colm_sm" data-aos="zoom-in" data-aos-duration="500">
                                <h4>Individual Investment Evaluation</h4>
                                <h3>Minutes</h3>
                            </div>
                            <div class="colm_sm Adoption" data-aos="zoom-in" data-aos-duration="500">
                                <h4>Adoption By A Team</h4>
                                <h3>2-3 days</h3>
                            </div>
                            <div class="colm_sm Enterprise" data-aos="zoom-in" data-aos-duration="500">
                                <h4>Enterprise-wide formal portfolio and investment assessment platform</h4>
                                <h3>15 days</h3>
                            </div>
                        </div>
                        <div class="btn_wrap">
                            <a href="#" onClick={() => navigate('/signup')} style={{ cursor: "pointer" }} >Start now - Evaluate an idea or investment</a>
                        </div>

                        <div class="You_want">
                            <h3 class="tittle_small">You want everything handled for you?</h3>
                        </div>
                        <div class="Start_Small sub_block" data-aos="zoom-in" data-aos-duration="500">
                            <div class="colm_sb">
                                <div class="img_colm">
                                    <img class="desk" src="img/images/pic_col.jpg" />
                                    <img class="mob" src="img/images/pic_col-mob.png" />
                                </div>
                                <h3>Consulting</h3>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Easy engagement</span></p>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Quick turn around</span></p>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Expertise from the founder</span></p>
                            </div>
                            <div class="colm_sb">
                                <div class="img_colm">
                                    <img class="desk" src="img/images/marketing.jpg" />
                                    <img class="mob" src="img/images/marketing-mob.png" />
                                </div>
                                <h3>Research and Marketing</h3>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Build or validate your research</span></p>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Evaluate and execute your digital marketing</span></p>

                            </div>
                            <div class="colm_sb">
                                <div class="img_colm">
                                    <img class="desk" src="img/images/traing.jpg" />
                                    <img class="mob" src="img/images/traing-mob.png" />
                                </div>
                                <h3>Corporate Training</h3>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Infuse culture of digital prudence</span></p>
                                <p><img src="img/images/arrow_img_sm.png" /><span>Customized training program for rapid adoption</span></p>

                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section>
                <div class="You-needed" data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="container">
                        <div class="You_needed_row">

                            <div class="You_needed_text">
                                <h1 class="tittle_main">You needed GateZero yesterday, act now.</h1>
                                <h2 class="sub_hed">A note to C-Level leaders.</h2>
                                <p>GateZero empowers you with unparalleled foresight and strategic options. It equips you with cutting-edge tools to eliminate major failures, mitigate risks, and drive business growth. Ensure seamless customer adoption and lead your organization with confidence and clarity. With GateZero, no investment will fail under your watch. It’s an indispensable tool for every initiative you undertake. Experience the transformational power of GateZero and revolutionize your approach to technology investments. Once you use it, you'll never embark on a project without it.</p>
                            </div>
                            <div class="You_needed_img">
                                <img class="desk" src="img/images/needed_img.jpg" />
                                <img class="mob" src="img/images/needed_img_mob.jpg" />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="industries_sec" data-aos="fade-up"
                    data-aos-duration="800">
                    <div class="container">
                        <div class="industries_row">
                            <div class="industries_img">
                                <img src="img/images/indus-img.jpg" />
                            </div>
                            <div class="industries_text">
                                <h1 class="tittle_main">Evaluate an initiative or your entire portfolio.</h1>
                                <p>Our SaaS solution is a versatile platform designed to work across
                                    all industries. It offers abstracted framework and scorecards that apply to any technology initiative, be it cloud engineering, data engineering and integration, app modernization, AI and ML solutions or just an idea or innovation being evaluated. With scalable architecture and secure cloud-based infrastructure, it delivers value to the unique needs of any business.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div class="testimonal" data-aos="fade-up"
                    data-aos-duration="800">
                    <h1 class="tittle_main">What customers are saying</h1>

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12" >
                                <Carousel
                                    interval={2000}
                                    controls={false}
                                    pause={true}
                                    variant='dark'
                                >
                                    <Carousel.Item active>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="main-mtn">
                                                    <p>Innovation is a tricky space, one has to be optimistic and yet have a healthy detachment from their idea. Gates of go / no-go decisions are many and makes innovative product launches tricky. Without a structured framework unbridled optimism leads to millions of wasted dollars, countless hours of wasted effort. The GateZero addresses the why and what part very effectively. We cannot imagine doing any digital solution without using the tools going ahead.</p>
                                                    <div class="right-textinal">
                                                        <img src="img/images/right-coln.png" />
                                                    </div>
                                                    <div class="profile_client">
                                                        <img src="img/images/icon-testi.png" alt="..." />
                                                    </div>
                                                    <h5>SATISH MADHIRA</h5>
                                                    <span>Founder ZeMoSo Technology</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="main-mtn">
                                                    <p>The framework for go/no go decisions is very accurate. I am responsible for envisioning and formulating digital solutions and plan to use the GateZero tools from now on. If you are in a position to make mission critical digital investment decisions or want to launch a successful digital product, look no further! The GateZero is a must. </p>
                                                    <div class="right-textinal">
                                                        <img src="img/images/right-coln.png" />
                                                    </div>
                                                    <div class="profile_client">
                                                        <img src="img/images/icon-testi.png" alt="..." />
                                                    </div>
                                                    <h5>Sarah Elchantaf</h5>
                                                    <span>VP – Product and Resource Management</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="main-mtn">
                                                    <p>We are able reprioritize several of our digital transformation projects after using GateZero – and suspended a few initiatives with poor outcome predicted by the framework. Our four-day training went well and was well received by our top-level leadership and product owners. I highly recommend this new framework that is positioned to become mainstream soon.</p>
                                                    <div class="right-textinal">
                                                        <img src="img/images/right-coln.png" />
                                                    </div>
                                                    <div class="profile_client">
                                                        <img src="img/images/icon-testi.png" alt="..." />
                                                    </div>
                                                    <h5>Mark Roberts</h5>
                                                    <span>Senior Vice President – Zenosxy Tech</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            <section class="customer-sec" data-aos="fade-up"
                data-aos-duration="700">
                <div class="container">
                    <h1 class="tittle_main">Our customers</h1>
                    <div class="our_brand">
                        <a><img src="img/images/brand-1.png" /></a>
                        <a><img src="img/images/brand-2.png" /></a>
                        <a><img src="img/images/brand-3.png" /></a>
                        <a><img src="img/images/brand-4.png" /></a>
                        <a><img src="img/images/brand-5.png" /></a>
                        <a><img src="img/images/brand-6.png" /></a>
                    </div>
                </div>
            </section >
            <section class="footer-bnr">
                <div class="bnr-warper" style={{ backgroundImage: "url(img/images/f-banner.jpg)" }}>
                    <div class="container">
                        <div class="dow_load" data-aos="zoom-in" data-aos-duration="500">
                            <h1 class="tittle-sb">Download how GateZero predicts failure of 20 startups</h1>
                            <div class="btn_wrap">
                                <a href="https://gatezerodigital.com/landing">download now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
