import { Container, Form, Row, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { setBuyermotivation, setCruxalignment, setProblemScoreCard, setProblemValidation, setSolutionriskscore } from "../../Reducer/reducer";
import ReactLoading from "react-loading";
import VideoContainer from "../../Frontend/Component/VideoContainer";
import { setJson, getJson } from "../../service/auth";

export default function Buyermotivation() {
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");

  const form4 = useSelector((state) => state.initiative.form4value);
  const [issubmit, setSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [score, setScore] = useState(form4.score);
  const [quadrant, setQuadrant] = useState("");
  const [values, setValues] = useState([]);


  const [ps, setProblemScoreCard] = useState(0)
  const [pv, setProblemValidation] = useState(0)
  const [psms, setProblemScoreCardMarketsize] = useState(0)
  const [ca, setCruxalignment] = useState(0)
  const [sc, setSolutionscore] = useState(0)
  const [psf, setProblemScoreCardFrequency] = useState(0)
  const [r, setRevenue] = useState(0)
  const [sr, setSolutionriskscore] = useState(0)
  const [other, setpmcmother] = useState(0)

  const readOnly = useSelector((state) => state.scorereducer.scorevalue)

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setBuyermotivation({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const getpmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {

        if (data.problemscorecard.total >= 10) {
          setProblemScoreCard(2)
        } else {
          setProblemScoreCard(- 5)
        }

        if (data.problemvalidationscore.alltotal > 0) {
          setProblemValidation(2)
        } else {
          setProblemValidation(-3)
        }

        if (
          data.problemscorecard.marketsize == 0 ||
          data.problemscorecard.marketsize == 5
        ) {
          setProblemScoreCardMarketsize(1)
        } else {
          setProblemScoreCardMarketsize(-2)
        }

        if (data.cruxalignment.total >= 25) {
          setCruxalignment(4)
        } else {
          setCruxalignment(- 2)
        }

        if (data.solutionscorecard.total >= 10) {
          setSolutionscore(2)
        } else {
          setSolutionscore(-10)
        }

        if (data.problemscorecard.problemfrequency == 3) {
          setProblemScoreCardFrequency(2)

        } else {
          setProblemScoreCardFrequency(-3)


        }

        if (data.revenuescore.total <= 5) {
          setRevenue(- 3)

        } else {
          setRevenue(1)
        }

        if (data.solutionriskscore.total == 0) {
          setSolutionriskscore(0)


        } else {
          setSolutionriskscore(- 1)

        }

        // console.log(pmcmtotal)

        setpmcmother(data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption)

      }

    });
  };

  let buyertotal = (score) => {
    if (score >= 2) {
      return 1;
    } else {
      return -1;
    }
  };


  let pmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      // console.log(data);
      if (data) {

        let buyerdata = buyertotal(score);

        let finaldata = parseInt(ps + pv + psms + ca + sc + psf + r + sr + other) + parseInt(buyerdata)

        // console.log(buyerdata)
        setJson(JSON.stringify({ pmcmalignment: { ...data.pmcmalignment, total: parseInt(finaldata) } }), evaluation_id)

        navigate("/user/problemscorecard");
      }
    });
  };

  const backButton = (event) => {
    if (!readOnly) {
      event.preventDefault();
      event.stopPropagation();
      if (isNaN(score) === false) {
        var num = [-1, 0, 1, 2, 3, 4, 5, 6, -10, -4];
        if (num.includes(score)) {
          let data = { score, quadrant };
          setRedux(
            data,
            JSON.stringify({ buyermotivation: data }),
            evaluation_id
          );
          navigate(-1)
        }
      }
    } else {
      navigate(-1);
    }
  };

  const submitData = (event) => {
    if (!readOnly) {
      event.preventDefault();
      event.stopPropagation();
      if (isNaN(score) === false) {
        var num = [-1, 0, 1, 2, 3, 4, 5, 6, -10, -4];
        if (num.includes(score)) {
          let data = { score, quadrant };
          setRedux(
            data,
            JSON.stringify({ buyermotivation: data }),
            evaluation_id
          );


          pmcm();
        } else {
          setValidated(false);
          $(".noalert").show();
          window.scrollTo(0, 0);
        }
      } else {
        setValidated(true);
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/user/problemscorecard");
    }
  };

  const setData = (e) => {
    setQuadrant(e.target.value);

    if (e.target.value == 1) {
      setValues(quadrant1);
    } else if (e.target.value == 2) {
      setValues(quadrant2);
    } else if (e.target.value == 3) {
      setValues(quadrant3);
    } else if (e.target.value == 4) {
      setValues(quadrant4);
    } else {
      setValues([]);
    }
    getpmcm()

  };

  const quadrant1 = [
    { id: 1, value: "1", text: "No clear alternative exists" },
    { id: 2, value: "0", text: "Alternatives with pain points" },
    { id: 3, value: "-1", text: "Mature Alternatives Exists " },
  ];

  const quadrant2 = [
    { id: 1, value: "6", text: "No clear alternative exists" },
    { id: 2, value: "3", text: "Alternatives with pain points" },
    { id: 3, value: "2", text: "Mature Alternatives Exists" },
  ];

  const quadrant3 = [
    { id: 1, value: "-1", text: "No clear alternative exists" },
    { id: 2, value: "-4", text: "Alternatives with pain points" },
    { id: 3, value: "-10", text: "Mature Alternatives Exists" },
  ];

  const quadrant4 = [
    { id: 1, value: "2", text: "No clear alternative exists" },
    { id: 2, value: "1", text: "Alternatives with pain points" },
    { id: 3, value: "0", text: "Mature Alternatives Exists" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data.buyermotivation) {
        setScore(data.buyermotivation.score);
        setQuadrant(data.buyermotivation.quadrant);

        if (data.buyermotivation.quadrant == 1) {
          setValues(quadrant1);
        } else if (data.buyermotivation.quadrant == 2) {
          setValues(quadrant2);
        } else if (data.buyermotivation.quadrant == 3) {
          setValues(quadrant3);
        } else if (data.buyermotivation.quadrant == 4) {
          setValues(quadrant4);
        } else {
          setValues([]);
        }
      }
    }).finally(() => {
      setSubmit(false);
    })
  }, []);

  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  return (
    <div>
      <Header2 />
      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>
            <Row>

              {/* <div className="col-md-12">
              <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
              </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px' }}>
                    Buyer Motivation Parity Score
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>
                {loader}
                <p>
                  Buyer motivation parity informs how likely a target customer
                  is to pay for the services you intend to provide. How
                  important and urgent is it for the customer to solve the
                  problem for which you are providing a solution? And what are
                  your customer's current options to solve the problem? Refer to
                  the chart. Pick a quadrant and then pick a sub-category. Enter
                  the score in the box below.
                </p>
                <h3>Enter the chosen score below</h3>
                {/* <Form noValidate validated={validated}>
                  <Form.Group className="below-form" controlId="formBasictext">
                    <Form.Control
                      type="number"
                      placeholder="Score"
                      required 
                      defaultValue={score}
                      onChange={(e) => setScore(parseInt(e.target.value))}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                    <span className="text-danger noalert" style={{display:'none'}}>Select number from below chart</span>
                  </Form.Group>
                </Form> */}
                <Form className="row" noValidate validated={validated}>
                  <div className="col-md-3">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Select
                        value={quadrant}
                        disabled={readOnly ? true : false}
                        required
                        onChange={(e) => {
                          setData(e);
                        }}
                      >
                        <option value=""> -- Select Quadrant --</option>
                        <option value="1" >1st Quadrant</option>
                        <option value="2">2nd Quadrant</option>
                        <option value="3">3rd Quadrant</option>
                        <option value="4">4th Quadrant</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-3">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Select
                        disabled={readOnly ? true : false}
                        value={score}
                        required
                        onChange={(e) => setScore(parseInt(e.target.value))}
                      >
                        <option value="">-- Select a subcategory --</option>
                        {values.map((item) => (
                          <option value={item.value}>{item.text}</option>
                        ))}
                        ;
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Form>
              </div>
              <div className="buyer-motivation ">
                <div className="row">
                  <div className="col-md-12">
                    <div className="line-chart">
                      <img src="../img/BuyerMotivationImage.png" alt="" />
                    </div>
                    {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                    <div className="next-back-button">
                      {loader}
                      <Button
                        variant="primary button-green"
                        onClick={backButton}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[5, 6, 7]} />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
