import { Container, Form, Row, Button, Table } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { setProblemValidation } from "../../Reducer/reducer";
import ReactLoading from "react-loading";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";

export default function Problemvalidation() {
  let loader;
  let total = 0;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");

  const [issubmit, setSubmit] = useState(false);

  const [ps, setProblemScoreCard] = useState(0)
  const [bm, setbuyermotivation] = useState(0)
  const [psms, setProblemScoreCardMarketsize] = useState(0)
  const [ca, setCruxalignment] = useState(0)
  const [sc, setSolutionscore] = useState(0)
  const [psf, setProblemScoreCardFrequency] = useState(0)
  const [r, setRevenue] = useState(0)
  const [sr, setSolutionriskscore] = useState(0)
  const [other, setpmcmother] = useState(0)

  const scoredata = useSelector((state) => state.scorereducer.scorevalue)

  const checkData = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: parseInt(e.target.value),
    }));
    if (e.target.value != 0) {

    }
  }

  const [state, setState] = useState({
    Row11: 0,
    Row21: 0,
    Row31: 0,
    Row41: 0,
    Row51: 0,
    Row61: 0,
    Row71: 0,
    Row81: 0,
    total1: 0,
    total2: 0,
    total3: 0,
    alltotal: 0,
  });

  const getpmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {

        if (data.problemscorecard.total >= 10) {
          setProblemScoreCard(2)
        } else {
          setProblemScoreCard(- 5)


        }
        if (data.buyermotivation.score >= 2) {
          setbuyermotivation(1);
        } else {
          setbuyermotivation(-1)
        }

        if (
          data.problemscorecard.marketsize == 0 ||
          data.problemscorecard.marketsize == 5
        ) {
          setProblemScoreCardMarketsize(1)
        } else {
          setProblemScoreCardMarketsize(-2)
        }

        if (data.cruxalignment.total >= 25) {
          setCruxalignment(4)
        } else {
          setCruxalignment(- 2)
        }

        if (data.solutionscorecard.total >= 10) {
          setSolutionscore(2)
        } else {
          setSolutionscore(-10)
        }

        if (data.problemscorecard.problemfrequency == 3) {
          setProblemScoreCardFrequency(2)

        } else {
          setProblemScoreCardFrequency(-3)


        }

        if (data.revenuescore.total <= 5) {
          setRevenue(- 3)

        } else {
          setRevenue(1)
        }

        if (data.solutionriskscore.total == 0) {
          setSolutionriskscore(0)


        } else {
          setSolutionriskscore(- 1)

        }

        // console.log(pmcmtotal)

        setpmcmother(data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption)

      }

    });
  };

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setProblemValidation({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  const totalData = (data) => {
    let sum = 0;
    for (let [key, val] of Object.entries(data)) {
      if (
        key != "total1" &&
        key != "total2" &&
        key != "total3" &&
        key != "alltotal"
      ) {
        sum += val ? val : 0;
      }
    }
    return parseInt(sum);
  };

  const setData = (e) => {

    setState((prev) => ({
      ...prev,
      [e.target.name]: parseInt(e.target.value),
    }));

    $("select.Row").each(function () {
      if (this.value == 0) {
        //$(this).css("border", "solid 1px red");
        return;
      } else {
        $(this).css("border", "1px solid #ced3dd");
      }
    });
  }

  getpmcm()

  total = totalData(state);

  const totalDataRow1 = () => {
    let totalVal = state.Row11
      ? state.Row11
      : 0 + state.Row21
        ? state.Row21
        : 0 + state.Row31
          ? state.Row31
          : 0 + state.Row41
            ? state.Row41
            : 0 + state.Row51
              ? state.Row51
              : 0 + state.Row61
                ? state.Row61
                : 0 + state.Row71
                  ? state.Row71
                  : 0 + state.Row81
                    ? state.Row81
                    : 0;
    return totalVal;
  };

  let Validationstate = (score) => {
    if (score > 0) {
      return 2;
    } else {
      return -3;
    }
  };

  let pmcm = (total) => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      console.log(data);
      if (data) {

        let validationtotal = Validationstate(total);

        let finaldata = parseInt(ps + bm + psms + ca + sc + psf + r + sr + other) + parseInt(validationtotal)

        // console.log(buyerdata)
        setJson(JSON.stringify({ pmcmalignment: { ...data.pmcmalignment, total: parseInt(finaldata) } }), evaluation_id)

        navigate("/user/solutionscorecard");

      }
    });
  };

  const backButton = (event) => {
    if (!scoredata) {

      event.preventDefault();
      event.stopPropagation();

      setRedux(
        state,
        JSON.stringify({
          problemvalidationscore: {
            ...state,
            total1: totalDataRow1(),
            alltotal: total,
          },
        }),
        evaluation_id
      );
      navigate(-1);
  }else {
    navigate(-1);
}
  };

const submitData = (event) => {
  if (!scoredata) {

    event.preventDefault();
    event.stopPropagation();

    let trace = false;

    $("select.Row").each(function () {
      if (this.value == 0) {
        trace = true;
        $(this).css("border", "solid 1px red");
        return;
      } else {
        $(this).css("border", "1px solid #ced3dd");
      }
    });
    if (trace) {
      trace = false;
      window.scrollTo(0, 0);
    } else {
      setRedux(
        state,
        JSON.stringify({
          problemvalidationscore: {
            ...state,
            total1: totalDataRow1(),
            alltotal: total,
          },
        }),
        evaluation_id
      );
      pmcm(total);
    }
  } else {
    navigate("/user/solutionscorecard");
  }
};

useEffect(() => {
  window.scrollTo(0, 0);
  setSubmit(true);

  getJson(evaluation_id).then((res) => {
    let data = JSON.parse(res.data.data.json);
    if (data.problemvalidationscore) {
      setState({ ...data.problemvalidationscore });
    }
  }).finally(() => {
    setSubmit(false);
  })
}, []);

if (issubmit) {
  loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
}

return (
  <div>
    {" "}
    <Header2 />
    <div className="page-wraper">
      <div className="Probdesc-page">
        <Initiativeheader eid={evaluation_id} />
        <Container>
          <Row>
            {/* <div className="col-md-12">
                   
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => {
                          submitData(e);
                        }}
                      >
                        Next
                      </Button>
                    </div>
                  </div> */}
            <div className="dimentional-title">
              <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 style={{ marginRight: '40px' }}>
                  Problem Validation Scorecard
                </h2>
                <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
              </div>

              {loader}
              <p>
                Problem validation is a research-based activity. You fill this
                scorecard after you conduct customer research. For a first
                pass evaluation, you can attribute a score that is your first
                guess in each score below. For the second pass evaluation, you
                would plug in actual research data. Generally, real research
                data is vastly different from your initial understanding.
                Therefore problem validation with actual research is one of
                the most critical steps.
              </p>
            </div>
            <div className="table-list">
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th>Validation Topic</th>

                    <th>Validation<span style={{ fontSize: '13px' }}> - Select what percentage of customers agree with your understanding</span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>Problem or need</h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row11"
                          name="Row11"
                          className="Row"
                          value={state.Row11}
                          onChange={(e) => {
                            setData(e);
                            checkData(e);
                          }}

                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>Situation in which the problem happens</h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row21"
                          name="Row21"
                          className="Row"
                          value={state.Row21}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>Who in the situation is facing the problem?</h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row31"
                          name="Row31"
                          className="Row"
                          value={state.Row31}
                          onChange={(e) => {
                            setData(e);

                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>
                        What activity is being done which includes the
                        problem?
                      </h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row41"
                          name="Row41"
                          className="Row"
                          value={state.Row41}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>What is the root cause of the problem?</h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row51"
                          name="Row51"
                          className="Row"
                          value={state.Row51}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>What is the ideal outcome?</h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row61"
                          name="Row61"
                          className="Row"
                          value={state.Row61}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>Objections to the proposed solution?</h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row71"
                          name="Row71"
                          className="Row"
                          value={state.Row71}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h2><span className="text-danger">* </span>
                        How does proposed solution causes ideal outcome?
                      </h2>
                    </td>
                    <td>
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Select
                          disabled={scoredata ? true : false}
                          id="Row81"
                          name="Row81"
                          className="Row"
                          value={state.Row81}
                          onChange={(e) => {
                            setData(e);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-5">Below 30%</option>
                          <option value="3">Between 30% to 50%</option>
                          <option value="5">Above 50%</option>
                        </Form.Select>
                      </Form.Group>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <h2>
                        <b>Total</b>
                      </h2>
                    </td>
                    <td >
                      <Form.Group
                        className="form-group"
                        controlId="formGridState"
                      >
                        <Form.Control className="form-input-total" type="text" readOnly value={total} />
                      </Form.Group>{" "}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
            <div className="buyer-motivation ">
              <div className="row">
                <div className="col-md-12">
                  {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                  <div className="next-back-button">
                    {loader}
                    <Button
                      variant="primary button-green"
                      onClick={backButton}
                    >
                      Back
                    </Button>
                    <Button
                      variant="primary button-blue"
                      id="submit"
                      onClick={(e) => {
                        submitData(e);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
              <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
            </div>
            <VideoContainer ids={[9]} />
          </Row>
        </Container>
      </div>
    </div>
  </div>
);
}
